// env config
export const API_CONFIG = {
    API_URL: `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_APP_ID}`,
    API_URL_PUBLIC_EVENT: process.env.REACT_APP_SHARE_PUBLIC_EVENT,
    API_URL_PUBLIC_ARTICLE: process.env.REACT_APP_SHARE_PUBLIC_ARTICLE,
    API_URL_PUBLIC_NEWS: process.env.REACT_APP_SHARE_PUBLIC_NEWS,
    API_ID: process.env.REACT_APP_API_APP_ID,
    API_KEY: process.env.REACT_APP_API_KEY,
    API_TIME_OUT: parseInt(process.env.REACT_APP_API_TIMEOUT || '0'),
    CLIENT_CREDENTIALS_INTERNAL: process.env.REACT_APP_CLIENT_CREDENTIALS_INTERNAL
};

// keys for localstorage
export const STORAGE_KEY = {
    ACCESS_TOKEN: 'oldst_accesstoken',
    SIDEBAR_STATE: 'sidebar-state'
};

// WEBFONT config
export const WEBFONT_CONFIG = {
    google: {
        families: [
            'Poppins:300',
            'Poppins:400',
            'Poppins:500',
            'Poppins:600',
            'Poppins:700',
            'Poppins:800',
            'Poppins:900'
        ]
    }
};

export const colorBrandActive = '#0069e6';
export const colorBrandGreen = '#25b862';

// services
export const services = {
    oAuthService: 'oauth2-service/',
    userService: 'user-service',
    authenticationService: '/authentication-service',
    coreService: '/core-service',
    databaseService: '/database-service',
    messageService: '/message-service'
};

export const storageKey = {
    accessToken: 'accessToken'
};

export const grantType = {
    CLIENT_CREDENTIALS: 'client_credentials',
    REFRESH_TOKEN: 'refresh_token',
    PASSWORD: 'password'
};

export const mapBox = {
    api: process.env.REACT_APP_MAPBOX_API || 'https://api.mapbox.com/geocoding/v5/mapbox.places',
    key: process.env.REACT_APP_MAPBOX_KEY || 'pk.eyJ1IjoiYWxsZWdyIiwiYSI6ImNsMG1laGkzZzE0YzczYm05NzBsNXdibHgifQ.kF1clTa4BR01O5LkcO2Z_A'
};

export const webView = {
    pinboard: process.env.REACT_APP_WEBVIEW_PINBOARD,
    pinboardMobile: process.env.REACT_APP_WEBVIEW_PINBOARD_MOBILE,
    pinboardSupport: process.env.REACT_APP_WEBVIEW_PINBOARD_SUPPORT,
    events: process.env.REACT_APP_WEBVIEW_EVENTS,
    guid: process.env.REACT_APP_WEBVIEW_GUID,
    key: process.env.REACT_APP_WEBVIEW_KEY,
    manageEvents: process.env.REACT_APP_WEBVIEW_MANAGE_EVENTS,
    admin: process.env.REACT_APP_WEBVIEW_ADMIN,
    webviewSupportPinboard: process.env.REACT_APP_WEBVIEW_SUPPORT_PINBOARD,
    webviewTechSupport: process.env.REACT_APP_WEBVIEW_TECHNICAL_SUPPORT_FORM
};

export const registrationLink = process.env.REACT_APP_REGISTRATION_LINK;

export const elasticInfo = {
    api: process.env.REACT_APP_ELASTIC_API,
    key: process.env.REACT_APP_ELASTIC_API_KEY,
    registration: process.env.REACT_APP_REGISTRATION_ENDPOINT,
    createGroup: process.env.REACT_APP_CREATE_GROUP_ENDPOINT,
    questionResults: process.env.REACT_APP_SEND_QUESTION_RESULTS_ENDPOINT,
    eventDetails: process.env.REACT_APP_SEND_EVENT_DETAILS,
    pollResults: process.env.REACT_APP_SEND_POLL_RESULTS_ENDPOINT,
    bulkMessage: process.env.REACT_APP_SEND_EMAIL_BULK_INVITES,
    requestGroupCode: process.env.REACT_APP_REQUEST_GROUP_CODE,
    externalTermsAndCondition: process.env.REACT_APP_ACCEPTED_T_AND_C_ENDPOINT
};

export const firebaseConfig = {
    clientId: process.env.REACT_APP_FIREBASE_clientId,
    appId: process.env.REACT_APP_FIREBASE_appId,
    apiKey: process.env.REACT_APP_FIREBASE_apiKey,
    databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
    storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
    messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
    projectId: process.env.REACT_APP_FIREBASE_projectId,
    measurementId: process.env.REACT_APP_FIREBASE_measurementId
};

export const what3Words = {
    apiKey: process.env.WHAT3WORDS_API_KEY || 'FRGOQ1DB',
    api: process.env.WHAT3WORDS_API || 'https://api.what3words.com/v3/'
};

export const googleMaps = {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY
};

export const dynamicLink = {
    firebaseApiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
    firebaseDynamicLink: process.env.REACT_APP_FIREBASE_DYNAMIC_LINK
};

export const MA_PINBOARD_ID = process.env.REACT_APP_MA_GROUP_ID;

export const MAX_VOICE_RECORDING = process.env.REACT_APP_MAX_VOICE_RECORDING;

export const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE;

export const LANGUAGE = process.env.REACT_APP_LANGUAGE;

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const IS_MA_SUB_GROUP = process.env.REACT_APP_IS_MA_SUB_GROUP;
