import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ConnectionFiltersState = {
    industries?: string[];
    tempIndustries?: string[];
    regiments?: string[];
    tempRegiments?: string[];
    serviceYear?: {
        from?: number;
        to?: number;
    };
    tourBase?: {
        service?: string;
        from?: number;
        to?: number;
    };
    hasChanged?: boolean;
    coordinates?: {
        lat?: number;
        long?: number;
    };
    address?: string;
    mile?: number;
    tempAddress?: string;
    tempMile?: number;
    tempCoordinates?: {
        lat?: number;
        long?: number;
    };
};

export const initialState: ConnectionFiltersState = {
    industries: [],
    tempIndustries: [],
    regiments: [],
    tempRegiments: [],
    serviceYear: {
        from: undefined,
        to: undefined
    },
    tourBase: {
        service: undefined,
        from: undefined,
        to: undefined
    },
    hasChanged: false,
    coordinates: {
        lat: undefined,
        long: undefined
    },
    address: undefined,
    mile: undefined,
    tempAddress: undefined,
    tempCoordinates: {
        lat: undefined,
        long: undefined
    },
    tempMile: undefined
};

export const connectionFiltersSlice = createSlice({
    name: 'connectionFilters',
    initialState,
    reducers: {
        setIndustryFilter: (
            state,
            action: PayloadAction<ConnectionFiltersState>
        ) => {
            state.industries = action.payload.industries;
        },
        setIndustryFilterTemp: (
            state,
            action: PayloadAction<ConnectionFiltersState>
        ) => {
            state.tempIndustries = action.payload.tempIndustries;
        },
        setRegimentFilter: (
            state,
            action: PayloadAction<ConnectionFiltersState>
        ) => {
            state.regiments = action.payload.regiments;
        },
        setRegimentFilterTemp: (
            state,
            action: PayloadAction<ConnectionFiltersState>
        ) => {
            state.tempRegiments = action.payload.tempRegiments;
        },
        setServiceYear: (
            state,
            action: PayloadAction<ConnectionFiltersState>
        ) => {
            state.serviceYear = action.payload.serviceYear;
        },
        setTourBase: (state, action: PayloadAction<ConnectionFiltersState>) => {
            state.tourBase = action.payload.tourBase;
        },
        setFilters: (state, action: PayloadAction<ConnectionFiltersState>) => {
            state.tourBase = action.payload.tourBase;
            state.industries = action.payload.industries;
            state.tourBase = action.payload.tourBase;
            state.serviceYear = action.payload.serviceYear;
            state.regiments = action.payload.regiments;
        },
        setIndustryRegimentFilterTemp: (
            state,
            action: PayloadAction<ConnectionFiltersState>
        ) => {
            state.tempIndustries = action.payload.tempIndustries;
            state.tempRegiments = action.payload.tempRegiments;
        },
        clearFilters: () => ({
            ...initialState
        }),
        setHasChanged: (
            state,
            action: PayloadAction<ConnectionFiltersState>
        ) => {
            state.hasChanged = action.payload.hasChanged;
        },
        setAddress: (state, action: PayloadAction<ConnectionFiltersState>) => {
            state.address = action.payload.address;
            state.coordinates = action.payload.coordinates;
        },
        setMile: (state, action: PayloadAction<ConnectionFiltersState>) => {
            state.mile = action.payload.mile;
        },
        setTempAddress: (
            state,
            action: PayloadAction<ConnectionFiltersState>
        ) => {
            state.tempAddress = action.payload.tempAddress;
            state.tempCoordinates = action.payload.tempCoordinates;
        },
        setTempMile: (state, action: PayloadAction<ConnectionFiltersState>) => {
            state.tempMile = action.payload.tempMile;
        }
    }
});

export const {
    setIndustryFilter,
    setServiceYear,
    setTourBase,
    setFilters,
    setIndustryFilterTemp,
    setRegimentFilter,
    setRegimentFilterTemp,
    setIndustryRegimentFilterTemp,
    clearFilters,
    setHasChanged,
    setAddress,
    setMile,
    setTempAddress,
    setTempMile
} = connectionFiltersSlice.actions;

export default connectionFiltersSlice.reducer;
