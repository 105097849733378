/* eslint-disable camelcase */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedTabState = {
    selectedTabId: any
};

const initialState: SelectedTabState = {
    selectedTabId: {}
};

export const selectedTabSlice = createSlice({
    name: 'selectedTab',
    initialState,
    reducers: {
        setSelectedTabId: (
            state,
            action: PayloadAction<SelectedTabState>
        ) => {
            state.selectedTabId = action.payload.selectedTabId;
        },
        clearSelectedTabId: (state, action: PayloadAction<string>) => {
            state.selectedTabId[action.payload] = undefined;
        }
    }
});

export const {
    setSelectedTabId,
    clearSelectedTabId
} = selectedTabSlice.actions;

export default selectedTabSlice.reducer;
