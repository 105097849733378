import React, { useEffect, useState } from 'react';
import { colors } from '~/styles';
import { AvatarSize, BorderRadius, Color, TypographySize, Weight } from '~/types';
import Typography from '../Typography';
import './avatar.scss';

export type Props = {
    className?: string,
    uri?: string,
    initials?: string,
    fontSize?: TypographySize,
    initialsColor?: Color,
    backgroundColor?: Color,
    fontWeight?: Weight,
    size?: AvatarSize,
    borderRadius?: BorderRadius,
    isCircled?: boolean,
    borderWidth?: number,
    borderColor?: Color,
}

type InitialProps = {
    initials?: string,
    initialsColor?: Color,
    fontWeight?: Weight,
    fontSize?: TypographySize
};

const Initials = ({ initials, initialsColor, fontSize, fontWeight }: InitialProps) => {
    return <Typography className='text-uppercase' color={initialsColor} size={fontSize}
        weight={fontWeight}>
        {initials}
    </Typography>;
};

export default function Avatar({
    className = '',
    uri,
    initials,
    fontSize,
    initialsColor = 'neutral-0',
    backgroundColor = 'davy-grey-300',
    fontWeight = 'semi-bold',
    size = 60,
    borderRadius = 4,
    isCircled,
    borderColor = 'davy-grey-100',
    borderWidth
}: Props): JSX.Element {
    const [dynamicUri, setDynamicUri] = useState(uri);

    const borderedStyle = borderWidth
        ? {
            borderWidth,
            borderColor: colors[borderColor],
            borderStyle: 'solid'
        }
        : {};

    useEffect(() => {
        setDynamicUri(uri);
    }, [uri]);

    return (
        <div
            className={`
            avatar
            ${className}
            avatar-${size}
            radius-${borderRadius}
            avatar-${isCircled ? 'circle' : ''}`}
            style={{ ...borderedStyle, backgroundColor: colors[backgroundColor] }} >
            <div className='avatar__wrapper d-flex justify-content-center align-items-center h-100 w-100'>
                {dynamicUri && dynamicUri !== 'undefined'
                    ? (
                        <img
                            src={dynamicUri}
                            onError={(e) => {
                                setDynamicUri('');
                            }}
                            alt={initials}
                            className={`radius-${borderRadius}`} />
                    )
                    : (
                        <Initials
                            initials={initials}
                            initialsColor={initialsColor}
                            fontSize={fontSize}
                            fontWeight={fontWeight} />
                    )}
            </div>
        </div>
    );
}
