/* eslint-disable camelcase */
import API, { ELASTIC_API_REQUEST } from './api';
import { elasticInfo, services } from '~/config/constants';
import { RegisterInternal, StoryHelp, UserStatusByEmail, Visibilities } from '~/types';

const { coreService } = services;

export const updateProfile = async (id: string, data: {
    email: string,
    first_name: string,
    last_name: string,
    address: string,
    industry: string[],
    phone_number: string[],
    service_start_date: string,
    service_end_date?: string,
    image?: string
}) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/update-my-profile`,
            method: 'PUT',
            data: { ...data, email: data.email.toLowerCase() }
        }
    );
};

export const onBoardingUpdateProfile = async (id: string, data: {
    email: string,
    first_name: string,
    last_name: string,
    address: string,
    industry: string[],
    phone_number: string[],
    service_start_date: string,
    service_end_date?: string,
    image?: string
}) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/on-boarding/update-profile`,
            method: 'PUT',
            data: { ...data, email: data.email.toLowerCase() }
        }
    );
};

export const getProfile = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity`
        }
    );
};

export const updateTours = async (id: string, work_experience: Array<any>) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/tour-base`,
            method: 'POST',
            data: {
                work_experience
            }
        }
    );
};

export const getTourList = async (groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/tour-list/${groupId}`
        }
    );
};

export const getStoryHelp = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/story-and-help`
        }
    );
};

export const upSertStory = async (id: string, my_story: string, visibility: Visibilities, isUpdate?: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/${isUpdate ? 'update' : 'create'}-story`,
            method: isUpdate ? 'PUT' : 'POST',
            data: {
                my_story,
                visibility
            }
        }
    );
};

export const upSertHowCanIHelp = async (id: string, my_help: string, visibility: Visibilities, isUpdate?: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/${isUpdate ? 'update' : 'create'}-how-can-i-help`,
            method: isUpdate ? 'PUT' : 'POST',
            data: {
                my_help,
                visibility
            }
        }
    );
};

export const updateVisibility = async (id: string, visibility: string, type: StoryHelp) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/visibility/my-${type}`,
            method: 'PUT',
            data: {
                visibility
            }
        }
    );
};

export const getStoryHelpMember = async (id: string, memberId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/members/${memberId}/story-and-help`
        }
    );
};

export const onDeleteAccount = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/account`,
            method: 'DELETE'
        }
    );
};

export const onRegisterInternal = async (data: RegisterInternal, isOptional?: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/registration/user${isOptional ? '?optional=true' : ''}`,
            method: 'POST',
            unAuth: true,
            data
        }
    );
};

export const createUser = async (token: string) => {
    return await API(
        {
            endpoint: `${coreService}/create-user`,
            method: 'POST',
            token
        }
    );
};

export const onGetTermsAndConditionsStatus = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/terms-and-conditions`
        }
    );
};

export const onAcceptTermsAndConditions = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/terms-and-conditions`,
            method: 'POST'
        }
    );
};

export const onGetPublicTermsAndConditions = async () => {
    return await API(
        {
            endpoint: `${coreService}/admin/terms_and_conditions`
        }
    );
};

export const onGetServices = async () => {
    return await API(
        {
            endpoint: `${coreService}/admin/services`
        }
    );
};

export const onGetUserStatusByEmail = async (email: string): Promise<UserStatusByEmail> => {
    return await API({
        endpoint: `${coreService}/identity/status?email=${encodeURIComponent(email)}`
    });
};

export const updateActivationCode = async (email: string, activation_code: string) => {
    return await API({
        endpoint: `${coreService}/identity/activation-code`,
        method: 'PUT',
        data: {
            email,
            activation_code
        }
    });
};

export const onRequestGroupCode = async (path: string, body: any) => {
    return await ELASTIC_API_REQUEST({
        endpoint: `${coreService}/events/hook`,
        data: {
            url: `${elasticInfo.api!}${path!}`,
            ...body
        },
        method: 'POST'
    });
};

export const onExternalTermsAndConditions = async (path: string, body: any) => {
    return await ELASTIC_API_REQUEST({
        endpoint: `${coreService}/events/hook`,
        data: {
            url: `${elasticInfo.api!}${path!}`,
            ...body
        },
        method: 'POST'
    });
};

export const onGetIdentityUpdated = async (id: string) => {
    return await API({
        endpoint: `${coreService}/${id}/identity/update-account`
    });
};
