import React from 'react';
import './error-boundary.scss';
import PageError from '../PageError';

type Props = {
    className?: string
}

export default function ErrorBoundary({ className = '' }: Props): JSX.Element {
    return (
        <div className={`error-boundary ${className}`} >
            <PageError />
        </div>
    );
}
