/* eslint-disable camelcase */
import API from './api';
import { services } from '~/config/constants';
import { MessageNotification } from '~/types';

const { coreService, messageService } = services;

export const getApprovals = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/notifications/approval`
        }
    );
};

export const getConnectionRequests = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/notifications/connection-requests`
        }
    );
};

export const acceptConnection = async (id: string, member_id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/notifications/accept-connection`,
            method: 'POST',
            data: {
                member_id
            }
        }
    );
};

export const rejectConnection = async (id: string, member_id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/notifications/reject-connection`,
            method: 'POST',
            data: {
                member_id
            }
        }
    );
};

export const getConnectionRejects = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/notifications/connection-rejects`
        }
    );
};

export const getNotificationStatus = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/load-notifications`
        }
    );
};

export const updateNotificationStatus = async (id: string, data: {
    events: boolean,
    connections: boolean,
    requests: boolean
}) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/update-notifications`,
            method: 'PUT',
            data
        }
    );
};

export const onRegisterFirebaseToken = async (id: string, token: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/notifications/push-tokens`,
            method: 'POST',
            data: {
                token
            }
        }
    );
};

export const getPushNotifications = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/notifications/push-notifications`
        }
    );
};

export const updateCounter = async (id: string, counter: number = 0) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/notifications/counter`,
            method: 'PUT',
            data: {
                counter
            }
        }
    );
};

export const messageNotification = async (id: string, messageNotif: MessageNotification) => {
    return await API(
        {
            endpoint: `${messageService}/${id}/notifications/message`,
            method: 'POST',
            data: messageNotif
        }
    );
};

export const getChatNotificationStatus = async (id: string, chat_id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/chat/notifications?chat_id=${chat_id}`
        }
    );
};

export const updateChatNotificationStatus = async (id: string, chat_id: string, mute: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/chat/notifications`,
            method: 'PUT',
            data: {
                chat_id,
                mute
            }
        }
    );
};

export const seenMessages = async (id: string, chat_id: string) => {
    return await API(
        {
            endpoint: `${messageService}/${id}/messaging/seen`,
            method: 'POST',
            data: {
                chat_id
            }
        }
    );
};

export const getNotifications = async (id: string, is_all = false) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/notifications/all?all=${is_all}`
        }
    );
};
