import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthenticationState = {
    isAuthed?: boolean,
    isLoggingOut?: boolean,
    profileData?: Object,
    userToken?: null | string,
    refreshToken?: string,
    expiration?: any,
    firebaseAnonymousUser?: any,
    activationToken?: any,
    isCreatingPassword?: boolean,
    isCreatedUser?: boolean,
    isCreatingProfile?: boolean,
    isInitializedFirebase?: boolean,
    isAcceptedTermsAndConditions?: boolean,
    termsAndConditionsAction?: () => void,
    isMaintenance?: boolean,
    coBroweSession?: any,
    isShowedOpenAppPopup?: boolean,
}

const initialState: AuthenticationState = {
    isAuthed: false,
    isLoggingOut: false,
    profileData: {},
    userToken: null,
    refreshToken: undefined,
    firebaseAnonymousUser: {},
    activationToken: undefined,
    isCreatingPassword: false,
    isCreatedUser: false,
    isCreatingProfile: false,
    isInitializedFirebase: undefined,
    isAcceptedTermsAndConditions: true,
    termsAndConditionsAction: () => { },
    isMaintenance: false,
    coBroweSession: undefined,
    isShowedOpenAppPopup: false
};

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        updateUserToken: (state, action: PayloadAction<string>) => {
            state.isLoggingOut = false;
            state.userToken = action.payload;
        },
        authUser: (state, action: PayloadAction<AuthenticationState>) => {
            state.profileData = action.payload;
            state.isAuthed = true;
            state.refreshToken = action.payload.refreshToken;
            state.userToken = action.payload.userToken;
            state.isCreatingProfile = action.payload.isCreatingProfile;
        },
        updateProfileData: (state, action: PayloadAction<Object>) => {
            state.profileData = {
                ...state.profileData,
                ...action.payload
            };
        },
        setLoggingOut: () => ({
            ...initialState
        }),
        setFirebaseAnonymousUser: (state, action: PayloadAction<AuthenticationState>) => {
            state.firebaseAnonymousUser = action.payload.firebaseAnonymousUser;
        },
        setActivationToken: (state, action: PayloadAction<AuthenticationState>) => {
            state.activationToken = action.payload.activationToken;
            state.refreshToken = action.payload.refreshToken;
        },
        clearActivationToken: (state) => {
            state.activationToken = undefined;
        },
        setIsCreatingPassword: (state, action: PayloadAction<AuthenticationState>) => {
            state.isCreatingPassword = action.payload.isCreatingPassword;
        },
        setIsCreatedUser: (state, action: PayloadAction<AuthenticationState>) => {
            state.isCreatedUser = action.payload.isCreatedUser;
        },
        setIsInitializedFirebaseApp: (state, action: PayloadAction<AuthenticationState>) => {
            state.isInitializedFirebase = action.payload.isInitializedFirebase;
        },
        setIsCreatingProfile: (state, action: PayloadAction<AuthenticationState>) => {
            state.isCreatingProfile = action.payload.isCreatingProfile;
        },
        setIsAcceptedTermsAndConditions: (state, action: PayloadAction<AuthenticationState>) => {
            state.isAcceptedTermsAndConditions = action.payload.isAcceptedTermsAndConditions;
        },
        setTermsAndConditionsAction: (state, action: PayloadAction<AuthenticationState>) => {
            state.termsAndConditionsAction = action.payload.termsAndConditionsAction;
        },
        clearIsCreatingPassword: (state) => {
            state.isCreatingPassword = false;
        },
        clearIsCreatedUser: (state) => {
            state.isCreatingPassword = false;
        },
        setIsMaintenance: (state, action: PayloadAction<AuthenticationState>) => {
            state.isMaintenance = action.payload.isMaintenance;
        },
        setCoBroweSession: (state, action: PayloadAction<AuthenticationState>) => {
            state.coBroweSession = action.payload.coBroweSession;
        },
        setIsShowedOpenAppPopup: (state, action: PayloadAction<AuthenticationState>) => {
            state.isShowedOpenAppPopup = action.payload.isShowedOpenAppPopup;
        }
    }
});

export const { updateUserToken, authUser, updateProfileData, setLoggingOut, setFirebaseAnonymousUser, setActivationToken, clearActivationToken, setIsCreatingPassword, setIsCreatedUser, setIsCreatingProfile, clearIsCreatingPassword, clearIsCreatedUser, setIsInitializedFirebaseApp, setIsAcceptedTermsAndConditions, setTermsAndConditionsAction, setIsMaintenance, setCoBroweSession, setIsShowedOpenAppPopup } = authenticationSlice.actions;

export default authenticationSlice.reducer;
