import { toast } from 'react-toastify';
import check from '~/assets/icons/check-o.svg';
import errorIcon from '~/assets/icons/error-o.svg';
import close from '~/assets/icons/close.svg';
import useError from './useError';

const useShowToast = () => {
    const onLogError = useError();

    const CloseButton = ({ closeToast }: any) => <span onClick={closeToast}>
        <img src={close} alt='close' />
    </span>;

    const onSuccess = (message: string) => {
        toast.success(message, {
            icon: <img src={check} alt='check' />,
            closeButton: CloseButton
        });
    };

    const onError = async (message: string, error?: any) => {
        if (message?.toLowerCase()?.includes('timeout')) {
            return;
        }

        if (!message?.includes("object of type 'NoneType' has no len()")) {
            toast.error(message, {
                icon: <img src={errorIcon} alt='error' />,
                closeButton: CloseButton
            });
        }

        await onLogError(`${message}: ${JSON.stringify(error)}}`);
    };

    return {
        onSuccess,
        onError
    };
};

export default useShowToast;
