import { isEmpty } from 'lodash';
import { isValidEmail } from '../utils/helpers';

const useRedirectSocialMedia = () => {
    const onRedirectToFacebook = async (groupId: string) => {
        const webLink = `https://www.facebook.com/${groupId}`;

        onRedirectUrl(webLink);
    };

    const onRedirectToWhatsApp = (groupId: string) => {
        const webLink = `https://chat.whatsapp.com/${groupId}`;

        onRedirectUrl(webLink);
    };

    const onRedirectToHelpLine = async (helpline: string) => {
        if (isValidEmail(helpline)) {
            onRedirectEmail(helpline);
        } else {
            onRedirectTel(helpline);
        }
    };

    const onRedirectUrl = (url: string) => {
        window.location.replace(!url?.startsWith('http') ? `https://${url}` : url);
    };

    const onOpenNewTab = (url: string) => {
        if (isEmpty(url)) return;

        window.open(!url?.startsWith('http') ? `https://${url}` : url);
    };

    const onRedirectTel = (tel: string) => {
        const phoneLink = `tel:${tel}`;

        onRedirectUrl(phoneLink);
    };

    const onRedirectEmail = async (email: string) => {
        const emailLink = `mailto:${email}`;

        onRedirectUrl(emailLink);
    };

    return {
        onRedirectToFacebook,
        onRedirectToWhatsApp,
        onRedirectToHelpLine,
        onRedirectUrl,
        onOpenNewTab,
        onRedirectTel,
        onRedirectEmail
    };
};

export default useRedirectSocialMedia;
