import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ServiceState = {
    services?: any[],
};

const initialState: ServiceState = {
    services: []
};

export const serviceSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setServices: (state, action: PayloadAction<ServiceState>) => {
            state.services = action.payload.services;
        }
    }
});

export const { setServices } = serviceSlice.actions;

export default serviceSlice.reducer;
