import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PublicEventState = {
    popupTimeout?: number;
};

const initialState: PublicEventState = {
    popupTimeout: undefined
};

export const publicEventSlice = createSlice({
    name: 'publicEvent',
    initialState,
    reducers: {
        setPopupTimeout: (state, action: PayloadAction<PublicEventState['popupTimeout']>) => {
            state.popupTimeout = action.payload;
        },
        clearPopupTimeout: () => ({
            ...initialState
        })
    }
});

export const { setPopupTimeout, clearPopupTimeout } = publicEventSlice.actions;

export default publicEventSlice.reducer;
