import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EventVisibilityState = {
    primaryGroups?: any[],
    subGroups?: any[],
    selectedAllPrimaryGroups?: boolean,
    isPublic?: boolean,
    primaryGroupsWot?: any[],
};

const initialState: EventVisibilityState = {
    primaryGroups: undefined,
    subGroups: undefined,
    selectedAllPrimaryGroups: undefined,
    isPublic: undefined,
    primaryGroupsWot: undefined
};

export const eventVisibilitySlice = createSlice({
    name: 'eventVisibility',
    initialState,
    reducers: {
        setEventVisibility: (state, action: PayloadAction<EventVisibilityState>) => {
            state.primaryGroups = action.payload.primaryGroups;
            state.subGroups = action.payload.subGroups;
            state.selectedAllPrimaryGroups = action.payload.selectedAllPrimaryGroups;
            state.isPublic = action.payload.isPublic;
            state.primaryGroupsWot = action.payload.primaryGroupsWot;
        },
        clearEventVisibility: () => ({
            ...initialState
        })
    }
});

export const { setEventVisibility, clearEventVisibility } = eventVisibilitySlice.actions;

export default eventVisibilitySlice.reducer;
