import React from 'react';
import './modal-confirm.scss';
import Button from '~/components/base/Button';
import Typography from '~/components/base/Typography';
import { ButtonVariant } from '~/components/base/Button/Button';

type Props = {
    className?: string;
    leftButtonTitle: string;
    rightButtonTitle: string;
    onLeft: () => void;
    onRight: () => void;
    description?: string;
    leftButtonVariant?: ButtonVariant;
    rightButtonVariant?: ButtonVariant;
};

export default function ModalConfirm({
    className,
    leftButtonTitle,
    rightButtonTitle,
    onLeft,
    onRight,
    description,
    leftButtonVariant,
    rightButtonVariant
}: Props): JSX.Element {
    return (
        <div className={`modal-confirm ${className}`}>
            <Typography size={16} color='neutral-400' weight='regular'>
                {description || 'Do you want to save your changes before exit?'}
            </Typography>
            <div className='modal-confirm__button-container'>
                <Button
                    className='modal-confirm__button--left'
                    size='lg'
                    variant={leftButtonVariant || 'secondary'}
                    label={leftButtonTitle}
                    onClick={onLeft}/>
                <Button
                    className='modal-confirm__button--right'
                    size='lg'
                    variant={rightButtonVariant || 'primary'}
                    label={rightButtonTitle}
                    onClick={onRight}/>
            </div>
        </div>
    );
}
