import { EXTERNAL_API_REQUEST as req } from './api';
import { dynamicLink } from '~/config/constants';
import { DYNAMIC_LINK } from '~/types';

export const generateDynamicLink = async ({
    androidPackageName,
    domainUriPrefix,
    iosAppStoreId,
    iosBundleId,
    link
}: DYNAMIC_LINK) => {
    return await req(
        dynamicLink.firebaseDynamicLink!,
        `?key=${dynamicLink.firebaseApiKey}`,
        JSON.stringify({
            dynamicLinkInfo: {
                domainUriPrefix,
                link,
                androidInfo: {
                    androidPackageName
                },
                iosInfo: {
                    iosBundleId,
                    iosAppStoreId
                },
                navigationInfo: {
                    enableForcedRedirect: true
                }
            }
        }),
        {
            'Content-Type': 'application/json'
        },
        'POST',
        true
    );
};
