import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EventW3WState = {
    w3wUrl?: string;
    address?: string;
    country?: string;
    region?: string;
    coordinates?: any;
    district?: string;
};

const initialState: EventW3WState = {
    w3wUrl: undefined,
    address: undefined,
    region: undefined,
    country: undefined,
    coordinates: undefined,
    district: undefined
};

export const eventW3WSlice = createSlice({
    name: 'eventW3W',
    initialState,
    reducers: {
        setEventW3W: (state, action: PayloadAction<EventW3WState>) => {
            state.w3wUrl = action.payload.w3wUrl;
            state.address = action.payload.address;
            state.country = action.payload.country;
            state.region = action.payload.region;
            state.coordinates = action.payload.coordinates;
            state.district = action.payload.district;
        },
        clearEventW3W: () => ({
            ...initialState
        })
    }
});

export const { setEventW3W, clearEventW3W } = eventW3WSlice.actions;

export default eventW3WSlice.reducer;
