/* eslint-disable camelcase */
import API from './api';
import { services } from '~/config/constants';

const { authenticationService, coreService, userService } = services;

export const login = async (email: string, password: string, device_id: string, token?: string) => {
    return await API({
        endpoint: `${authenticationService}/login`,
        method: 'POST',
        data: {
            email: email.toLowerCase(),
            password,
            device_id
        },
        unAuth: true,
        token
    }
    );
};

export const onRefreshToken = async (refresh_token: string) => {
    return await API(
        {
            endpoint: `${authenticationService}/token/refresh-token`,
            method: 'POST',
            data: {
                refresh_token
            },
            unAuth: true
        }
    );
};

export const forgotPassword = async (email: string) => {
    return await API(
        {
            endpoint: `${authenticationService}/forgot-password`,
            method: 'POST',
            data: {
                email: email.toLowerCase()
            }
        }

    );
};

export const forgotPasswordOTP = async (email: string, otp: string) => {
    return await API(
        {
            endpoint: `${authenticationService}/forgot-password/${otp}`,
            method: 'POST',
            data: {
                email: email.toLowerCase()
            }
        }
    );
};

export const changePassword = async (id: string, password: string, device_id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/change-password`,
            method: 'POST',
            data: {
                password,
                device_id
            }
        }
    );
};

export const activateRegistrationCode = async (code: string) => {
    return await API(
        {
            endpoint: `${authenticationService}/registration/code/${code}`,
            method: 'POST'
        }
    );
};

export const onCheckOldPassword = async (id: string, password: string) => {
    return await API(
        {
            endpoint: `${userService}/${id}/verify-password`,
            method: 'POST',
            data: {
                password
            }
        }
    );
};

export const onRegisterDevice = async (id: string, device_id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/device`,
            method: 'POST',
            data: {
                device_id
            }
        }
    );
};

export const onGetAppVersion = async () => {
    return await API({
        endpoint: `${coreService}/versions`
    });
};

export const onChangePasswordRequest = async (id: string, new_email: string) => {
    return await API({
        endpoint: `${coreService}/${id}/identity/change-email`,
        data: {
            new_email
        },
        method: 'POST'
    });
};

export const onChangePasswordValidate = async (id: string, otp: string, device_id: string, email: string) => {
    return await API({
        endpoint: `${authenticationService}/${id}/identity/change-email-otp-check`,
        data: {
            otp,
            device_id,
            email
        },
        method: 'POST'
    });
};
