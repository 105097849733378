import React from 'react';
import './background-wrapper.scss';
import useBranding from '~/hooks/useBranding';

type Props = {
    className?: string,
    children?: React.ReactNode
}

export default function BackgroundWrapper({ className = '', children }: Props): JSX.Element {
    const { logo } = useBranding();

    return (
        <div className={`background-wrapper ${className}`} >
            <div className='background-wrapper__content'>
                <img src={logo} alt='logo' className='background-wrapper__content-logo' />
                {children}
            </div>
        </div>
    );
}
