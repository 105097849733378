/* eslint-disable camelcase */
import { isArray, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useAppSelector } from '~/redux/hooks';
import { setChatLatestMessage, setChats, setMembers, setPendingPolls, setPolls } from '~/redux/modules/message';
import { setIsHasSeen } from '~/redux/modules/profile';
import type { Chat, GenerateChat, Message, MessageChatPositionUpdate } from '~/types';
import FireStore from '~/utils/Firestore';
import { onGetDataFromFireStore, onGetFirestoreChanges } from '~/utils/helpers';
import { useAppDispatch } from '../redux/hooks';
import { v4 as uuidv4 } from 'uuid';
import { getMembersByIds } from '~/api/groupMember';
import { isEqualObjects } from '../utils/helpers';
import useBranding from './useBranding';
import { getChats } from '~/api/chats';
import useShowToast from './useShowToast';

const useChats = () => {
    const { branding } = useBranding();
    const COLLECTION = branding?.firestoreChatsCollection!;
    const dispatch = useAppDispatch();
    const { id, chatsLatestMessage, isHasSeen, chats, members: MEMBERS, isAuthed, isUpdatedIgnore } = useAppSelector(({ message, profile, authentication }) => ({ ...message, ...profile, ...authentication }));
    const fireStore = new FireStore(COLLECTION);
    const [isLoadingChats, setIsLoadingChats] = useState(!chats?.length);
    const { onError } = useShowToast();

    const onAddChat = async (chat: Chat) => {
        return (await fireStore.add(chat))?.id;
    };

    const onUpdateChat = async (doc: string, data: any) => {
        return await fireStore.update(doc, { ...data, updated_date: fireStore.getServerTime() });
    };

    const onUpdateLatestMessage = async (doc: string, messageId: string) => {
        return await onUpdateChat(doc, {
            latest_message: messageId
        });
    };

    const onGetChat = async (doc: string) => {
        return await fireStore.getDoc(doc);
    };

    const onGetChats = async () => {
        return await fireStore.getCollections();
    };

    const onGetChatByGroupId = async (groupId: string) => {
        const chats = await fireStore.getCollections('group_id', '==', groupId);

        return isArray(chats?.docs) && !isEmpty(chats?.docs) ? onGetDataFromFireStore(chats?.docs)[0] : undefined;
    };

    const onGetChatsByGroupIds = async (groupId: string[]) => {
        const chats = await fireStore.getStore().collection(COLLECTION).where('group_id', 'in', groupId).orderBy('group_name', 'asc').get();

        return isArray(chats?.docs) ? onGetDataFromFireStore(chats?.docs) : [];
    };

    const onGetChatBySender = async (sender: string, receiver: string) => {
        const chats = await fireStore.getStore()
            .collection(COLLECTION)
            .where('sender', '==', sender)
            .where('receiver', '==', receiver)
            .get();

        return isArray(chats?.docs) && !isEmpty(chats?.docs) ? onGetDataFromFireStore(chats?.docs[0]) : undefined;
    };

    const onGetChatByDM = async (sender: string, receiver: string) => {
        return (await onGetChatBySender(sender, receiver)) || (await onGetChatBySender(receiver, sender));
    };

    const onGenerateChat = ({ messageId = '', chatName = '', chatType = 'DIRECT', group_id = '', members = [], photo = '', sender = '', receiver = '', unSentMessage }: GenerateChat): Chat => {
        const chat = {
            localId: uuidv4(),
            created_date: fireStore.getServerTime(),
            deleted: false,
            group_photo: photo,
            latest_message: messageId,
            name: chatName,
            type: chatType,
            updated_date: fireStore.getServerTime(),
            group_id: group_id,
            members: members,
            sender,
            receiver,
            unSentMessage
        };

        if (!unSentMessage) delete chat.unSentMessage;

        return chat;
    };

    const onSetLatestMessage = async (newChats: Chat[]) => {
        try {
            if (isEqualObjects(newChats, chats || {})) return;

            let latestMessages = {};

            const messages = await Promise.all(newChats?.map(chat => {
                if (chat?.latest_message) return fireStore.getCustomCollectionByDoc(branding?.firestoreMessagesCollection!, chat?.latest_message);
            }));

            if (!isEmpty(messages)) {
                for (const doc of messages) {
                    if (doc?.data()) {
                        const message = onGetDataFromFireStore(doc || {});

                        if (message?.id && (!chatsLatestMessage || (chatsLatestMessage && message?.text !== chatsLatestMessage[message?.id as keyof typeof chatsLatestMessage]?.text))) {
                            if (message?.text || !isEmpty(message?.metadata) || !isEmpty(message?.images)) {
                                latestMessages = {
                                    ...latestMessages,
                                    [message?.chatId!]: message
                                };
                            }
                        }
                    }
                }
            }

            onDispatchLatestMessages({ ...chatsLatestMessage, ...latestMessages });
        } catch (error) {
            onError(`Unable to set latest messages: ${(error as Error)?.message}`);
        }
    };

    // const onDispatchMessages = async (chats: Chat[]) => {
    //     if (!isEmpty(messages)) return;

    //     const chatIds = chats?.map(chat => chat.id);

    //     const newMessages = await fireStore.getStore().collection(branding?.firestoreMessagesCollection!).where('chatId', 'in', chatIds).orderBy('date', 'desc').get();
    //     const formattedMessages = onGetDataFromFireStore(newMessages.docs || []);
    // };

    const onSetPolls = async (chats: Chat[]) => {
        const messages = await Promise.all(chats?.map(async chat => {
            const data = await fireStore.getStore().collection(branding?.firestoreMessagesCollection!)
                .where('type', '==', 'POLL')
                .where('chatId', '==', chat.id)
                .get();

            return data.docs;
        }));

        let pendingPolls: any = {};
        let polls: any = {};

        for (const data of messages) {
            if (data?.length > 0) {
                data?.forEach((doc: any) => {
                    const message: Message = onGetDataFromFireStore(doc);
                    const notDeleted = !message?.deleted;
                    const isPending = notDeleted && !message?.poll?.ignored?.includes(id!) && !message?.poll?.options?.filter(option => option?.answered?.includes(id!))?.length;

                    if (isPending && !message?.poll?.closed && notDeleted) {
                        pendingPolls = { ...pendingPolls, [message?.chatId!]: (pendingPolls[message?.chatId!] || 0) + 1 };
                    }

                    if (notDeleted) {
                        polls = { ...polls, [message?.chatId!]: [...(polls[message?.chatId!] || []), { ...message, isPending }] };
                    }
                });
            }
        }

        onDispatchPendingPolls(pendingPolls);
        onDispatchPolls(polls);
    };

    const onDispatchChats = async (chats: Chat[]) => {
        // await onDispatchMessages(chats);
        await onSetLatestMessage(chats);
        await onSetPolls(chats);

        dispatch(setChats({
            chats: chats || []
        }));
    };

    const onDispatchLocalChats = (chat: Chat) => {
        dispatch(setChats({
            chats: [...(chats || []), chat]
        }));
    };

    const onDispatchLatestMessages = (chatsLatestMessage: any) => {
        dispatch(setChatLatestMessage({
            chatsLatestMessage
        }));
    };

    const onDispatchPendingPolls = (pendingPolls: any) => {
        dispatch(setPendingPolls({
            pendingPolls
        }));
    };

    const onDispatchPolls = (polls: any) => {
        dispatch(setPolls({
            polls
        }));
    };

    const onDispatchIsHasSeen = () => {
        if (isHasSeen) {
            dispatch(setIsHasSeen({
                isHasSeen: false
            }));
        }
    };

    const onGetMembers = useCallback(async (members: string[]) => {
        try {
            if (isEmpty(members) && !id) return;

            const { response } = await getMembersByIds(id!, members);

            let membersInfo = {};

            if (isArray(response)) {
                for (let i = 0; i < response.length; i++) {
                    membersInfo = { ...membersInfo, [response[i]?.user_id]: response[i] };
                }
            }

            if (!isEmpty(membersInfo)) {
                dispatch(setMembers({
                    members: { ...MEMBERS, ...membersInfo }
                }));
            }

            return true;
        } catch (error) {
            return error;
        }
    }, [MEMBERS]);

    const onDispatchMembers = useCallback(async (chats: Chat[]) => {
        let chatMembers: any[] = [];

        for (const chat of chats) {
            chatMembers = [...chatMembers, ...(chat?.members || []).filter(memberId => memberId !== id)];
        }

        if (!isEmpty(chatMembers)) {
            await onGetMembers(chatMembers);
        }
    }, [MEMBERS]);

    const onDeleteUserChats = async () => {
        let chatIds: any = [];
        const chats = await fireStore.getStore()
            .collection(COLLECTION)
            .where('type', '==', 'DIRECT')
            .where('members', 'array-contains', id)
            .get();

        if (chats?.docs) {
            const batch = fireStore.getStore().batch();

            for (const chat of chats?.docs) {
                chatIds = [...chatIds, chat.id];
                batch.delete(chat.ref);
            }

            await batch.commit();

            return chatIds;
        }
    };

    const onGetChatsByUser = async () => {
        try {
            const data = await fireStore.getStore()
                .collection(COLLECTION)
                .where('members', 'array-contains', id)
                .orderBy('updated_date', 'desc')
                .get();

            const chats = onGetDataFromFireStore(data.docs || []);

            onDispatchChats(chats);
            onDispatchMembers(chats);

            return chats;
        } catch (error) {
            onError(`Unable to fetch user chats: ${(error as Error)?.message}`);

            return [];
        }
    };

    const onHandleGetChats = async (start?: string, changes?: string, chatLimit?: number, position?: MessageChatPositionUpdate) => {
        try {
            const { chats } = await getChats(id!, chatLimit, start, changes);

            dispatch(setChats({
                chats,
                position
            }));

            setIsLoadingChats(false);

            return chats;
        } catch (error) {
            console.log('Unable to get chats', error);

            setIsLoadingChats(false);

            return [];
        }
    };

    const subscribe = useCallback(() => {
        if (id && isAuthed) {
            return fireStore.getStore()
                .collection(COLLECTION)
                .where('members', 'array-contains', id)
                .orderBy('updated_date', 'desc')
                .limit(10)
                .onSnapshot((querySnapshot: any) => {
                    if (querySnapshot) {
                        const changes = onGetDataFromFireStore(querySnapshot?.docChanges());

                        const isNeedToFetch = onGetFirestoreChanges(changes);

                        if (isNeedToFetch) {
                            let changesIds = '';

                            if (changes?.length < 10) {
                                changesIds = changes?.map((change: any) => change?.id).join('|');
                            }

                            onHandleGetChats('', changesIds, changes?.length, !changesIds ? 'ALL' : undefined);
                        }
                    }
                });
        }
    }, [id, isAuthed, COLLECTION]);

    return {
        onAddChat,
        onUpdateChat,
        onGetChat,
        onGetChats,
        onGetChatByDM,
        onGetChatByGroupId,
        onGenerateChat,
        onUpdateLatestMessage,
        onDispatchLocalChats,
        isLoadingChats,
        onDeleteUserChats,
        onGetChatsByGroupIds,
        onHandleGetChats,
        subscribe
    };
};

export default useChats;
