import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GroupInfoState = {
    groupMembers?: any,
    myGroups?: any,
    otherGroups?: any,
    groupUpComingEvents?: any,
    groupPastEvents?: any,
    tempRegionContacts?: any[],
    tempHelpLine?: any,
    tempLocation?: any,
    tempSocial?: any[],
    hasChanges?: boolean,
    isRefreshGroupDetails?: boolean,
    groupInvited?: any,
    articleType?: any[],
    groupCategories?: any[],
    eventInvited?: any,
    sections?: any,
    events?: any,
    articlesLastUpdated?: any,
    newsLastUpdated?: any,
    groupCrews?: any
};

const initialState: GroupInfoState = {
    groupMembers: {},
    myGroups: [],
    otherGroups: {},
    groupUpComingEvents: {},
    groupPastEvents: {},
    tempRegionContacts: undefined,
    tempHelpLine: undefined,
    tempLocation: undefined,
    tempSocial: undefined,
    hasChanges: false,
    isRefreshGroupDetails: false,
    groupInvited: {},
    articleType: [],
    groupCategories: [],
    eventInvited: {},
    sections: {},
    events: {},
    articlesLastUpdated: {},
    newsLastUpdated: {},
    groupCrews: {}
};

export const groupInfoSlice = createSlice({
    name: 'groupInfo',
    initialState,
    reducers: {
        setGroupMembers: (state, action: PayloadAction<GroupInfoState>) => {
            state.groupMembers = JSON.parse(JSON.stringify(action.payload.groupMembers));
        },
        setGroups: (state, action: PayloadAction<GroupInfoState>) => {
            state.myGroups = action.payload.myGroups;
            state.otherGroups = action.payload.otherGroups;
        },
        setGroupUpcomingEvents: (state, action: PayloadAction<GroupInfoState>) => {
            state.groupUpComingEvents = action.payload.groupUpComingEvents;
        },
        setGroupPastEvents: (state, action: PayloadAction<GroupInfoState>) => {
            state.groupPastEvents = action.payload.groupPastEvents;
        },
        setTempGroupContacts: (state, action: PayloadAction<GroupInfoState>) => {
            state.tempRegionContacts = action.payload.tempRegionContacts;
        },
        setTempHelpLine: (state, action: PayloadAction<GroupInfoState>) => {
            state.tempHelpLine = action.payload.tempHelpLine;
        },
        setTempLocation: (state, action: PayloadAction<GroupInfoState>) => {
            state.tempLocation = action.payload.tempLocation;
        },
        setTempSocial: (state, action: PayloadAction<GroupInfoState>) => {
            state.tempSocial = action.payload.tempSocial;
        },
        setHasChanges: (state, action: PayloadAction<GroupInfoState>) => {
            state.hasChanges = action.payload.hasChanges;
        },
        setIsRefreshGroupDetails: (state, action: PayloadAction<GroupInfoState>) => {
            state.isRefreshGroupDetails = action.payload.isRefreshGroupDetails;
        },
        clearTemp: (state) => {
            state.tempHelpLine = undefined;
            state.tempRegionContacts = undefined;
            state.tempLocation = undefined;
            state.tempSocial = undefined;
        },
        setGroupInvited: (state, action: PayloadAction<GroupInfoState>) => {
            state.groupInvited = action.payload.groupInvited;
        },
        setArticleType: (state, action: PayloadAction<GroupInfoState>) => {
            state.articleType = action.payload.articleType;
        },
        setGroupCategories: (state, action: PayloadAction<GroupInfoState>) => {
            state.groupCategories = action.payload.groupCategories;
        },
        clearGroupInfo: () => ({
            ...initialState
        }),
        setEventInvited: (state, action: PayloadAction<GroupInfoState>) => {
            state.eventInvited = action.payload.eventInvited;
        },
        setSections: (state, action: PayloadAction<GroupInfoState>) => {
            state.sections = action.payload.sections;
        },
        setEvents: (state, action: PayloadAction<GroupInfoState>) => {
            state.events = action.payload.events;
        },
        setArticlesLastUpdated: (state, action: PayloadAction<GroupInfoState>) => {
            state.articlesLastUpdated = { ...state.articlesLastUpdated, ...action.payload.articlesLastUpdated };
        },
        setNewsLastUpdated: (state, action: PayloadAction<GroupInfoState>) => {
            state.newsLastUpdated = { ...state.newsLastUpdated, ...action.payload.newsLastUpdated };
        },
        setGroupCrews: (state, action: PayloadAction<GroupInfoState>) => {
            state.groupCrews = { ...state.groupCrews, ...action.payload.groupCrews };
        }
    }
});

export const { setGroupMembers, setGroups, setGroupUpcomingEvents, setGroupPastEvents, setTempGroupContacts, setTempHelpLine, clearTemp, setTempLocation, setTempSocial, setHasChanges, setIsRefreshGroupDetails, setGroupInvited, setArticleType, setGroupCategories, clearGroupInfo, setEventInvited, setSections, setEvents, setArticlesLastUpdated, setNewsLastUpdated, setGroupCrews } = groupInfoSlice.actions;

export default groupInfoSlice.reducer;
