import { db } from '~/dexie/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { GroupNewsCache } from '~/dexie/dexieTypes';
import { getLatestNews } from '~/api/groups';
import { useAppSelector } from '~/redux/hooks';

const useGroupNews = (groupId?: string) => {
    const { id } = useAppSelector(({ profile }) => ({
        ...profile
    }));

    const groupNews = useLiveQuery(async () => {
        try {
            if (groupId) {
                const groupNews = await db.groupNews
                    .where('groupId')
                    .equals(groupId)
                    .toArray();

                if (groupNews && groupNews[0]) return groupNews[0];
            }
        } catch (error) {
            console.log(error);
        }
    }, [groupId]);

    const onRefetch = async () => {
        const news = await onGetGroupNewsArticles();

        onUpdateGroupNews({
            groupId,
            news
        });
    };

    const onGetGroupNewsArticles = async () => {
        let response: any;

        try {
            response = await getLatestNews(id!, groupId!, true);
        } catch (error) {
            if (error === 'Error: Invalid group id') {
                response = await getLatestNews(id!, groupId!, false);
            }
        }

        return response?.response;
    };

    const onUpdateGroupNews = async (groupNews: GroupNewsCache) => {
        try {
            const id = await db.groupNews.put(groupNews);

            return id;
        } catch (error) {
            console.log(error);
        }
    };

    const onClearGroupNews = () => {
        try {
            db.groupNews.clear();
        } catch (error) {
            console.log(error);
        }
    };

    const onGetArticle = (articleId: string) => {
        return groupNews?.news?.filter?.(article => (article?._id === articleId))?.[0];
    };

    return {
        groupNews,
        onUpdateGroupNews,
        onClearGroupNews,
        onRefetch,
        onGetArticle
    };
};

export default useGroupNews;
