import React from 'react';
import PageError from './PageError';

type Props = {
    className?: string
}

export default function PageErrorContainer({ className = '' }: Props): JSX.Element {
    const onReload = () => {
        window.location.reload();
    };

    return (
        <PageError
            className={className}
            onReload={onReload} />
    );
}
