import firebase from 'firebase';
import { useEffect } from 'react';
import { onRegisterFirebaseToken } from '~/api/notifications';
import { useAppSelector } from '~/redux/hooks';

const useFirebaseNotification = () => {
    const { id, isInitializedFirebase, isAuthed, isMaintenance } = useAppSelector(({ profile, authentication }) => ({ ...profile, ...authentication }));

    const requestPermission = async () => {
        if (!isInitializedFirebase || !firebase.messaging.isSupported() || !isAuthed) return;

        const messaging = firebase?.messaging();

        if (!id) return;

        const token = await messaging?.getToken();

        if (token) {
            await onRegisterFirebaseToken(id, token);
        }
    };

    useEffect(() => {
        if (!isInitializedFirebase || !firebase.messaging.isSupported() || !isAuthed || isMaintenance) return;

        const messaging = firebase?.messaging();

        const subscribe = messaging?.onMessage((payload: any) => {
            if (!payload?.notification) return;

            const notificationTitle = payload.notification.title;

            let formattedData = {};

            if (payload?.data?.str_data) {
                formattedData = JSON.parse(payload?.data?.str_data || '{}');
            }

            const notificationOptions = {
                body: payload.notification.body,
                icon: payload.data.chat_image,
                data: formattedData
            };

            if (notificationTitle) {
                navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
                    if (registration) {
                        registration.showNotification(notificationTitle, notificationOptions);
                    }
                });
            }
        });

        return subscribe;
    }, [isInitializedFirebase, isAuthed, isMaintenance]);

    useEffect(() => {
        requestPermission();
    }, [isInitializedFirebase, isAuthed, isMaintenance]);
};

export default useFirebaseNotification;
