import { db } from '~/dexie/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { GroupCache } from '~/dexie/dexieTypes';
import { getGroupInfo } from '~/api/groups';

const useGroupInfo = (groupId?: string) => {
    const groups = useLiveQuery(async () => {
        try {
            const groups = await db.groups.toArray();

            return groups;
        } catch (error) {
            console.log(error);
        }

        return [];
    }, []);

    const groupInfo = useLiveQuery(async () => {
        try {
            if (groupId) {
                const groups = await db.groups
                    .where('_id')
                    .equals(groupId)
                    .toArray();

                if (groups && groups[0]) return groups[0];
            }
        } catch (error) {
            console.log(error);
        }
    }, [groupId]);

    const onUpdateGroupInfo = async (newGroup: GroupCache) => {
        try {
            const id = await db.groups.put(newGroup);

            return id;
        } catch (error) {
            console.log(error);
        }
    };

    const onClearGroups = () => {
        try {
            db.groups.clear();
        } catch (error) {
            console.log(error);
        }
    };

    return {
        groupInfo,
        groups,
        onUpdateGroupInfo,
        onClearGroups
    };
};

export default useGroupInfo;
