import React from 'react';
import { ButtonVariant } from '~/components/base/Button/Button';
import ModalConfirm from '~/components/modules/ModalConfirm';
import { useAppDispatch } from '~/redux/hooks';
import { clearModalContent, setModalContent } from '~/redux/modules/modalEvent';

type Args = {
    leftArg?: any;
    rightArg?: any;
};

type ButtonType = {
    title: string;
    onClick: (arg?: any) => void;
    variant?: ButtonVariant
};

type Props = {
    className?: string;
    title: string;
    save: ButtonType;
    cancel: ButtonType;
    description?: string;
    onToggle?: () => void;
};

const useModalConfirm = ({
    className,
    title,
    save,
    cancel,
    description,
    onToggle
}: Props) => {
    const dispatch = useAppDispatch();

    const onCloseModal = () => {
        dispatch(clearModalContent());
    };

    const onHandleLeft = (leftArg?: any) => {
        onCloseModal();
        cancel.onClick(leftArg);
    };

    const onHandleRight = (rightArg?: any) => {
        onCloseModal();
        save.onClick(rightArg);
    };

    const onShowModal = (args?: Args) => {
        dispatch(
            setModalContent({
                title,
                modalContent: (
                    <ModalConfirm
                        className={className}
                        leftButtonTitle={cancel.title}
                        rightButtonTitle={save.title}
                        onLeft={() => onHandleLeft(args?.leftArg)}
                        onRight={() => onHandleRight(args?.rightArg)}
                        description={description}
                        leftButtonVariant={cancel.variant}
                        rightButtonVariant={save.variant} />
                ),
                onToggle
            })
        );
    };

    return onShowModal;
};

export default useModalConfirm;
