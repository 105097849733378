import React, { useState } from 'react';
import PublicEventBrowserPopup from './PublicEventBrowserPopup';
import useGenerateDynamicLink from '~/hooks/useGenerateDynamicLink';
import useRedirectSocialMedia from '~/hooks/useRedirectSocialMedia';
import { isMobile } from 'react-device-detect';
import useShowToast from '~/hooks/useShowToast';
import useBranding from '~/hooks/useBranding';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { setPopupTimeout } from '~/redux/modules/publicEvent';
import { isUndefined } from 'lodash';
import moment from 'moment';
import useHref from '~/hooks/useHref';
import { setIsShowedOpenAppPopup } from '~/redux/modules/authentication';

type Props = {
    className?: string;
    eventId?: string;
};

export default function PublicEventBrowserPopupContainer({
    className = '',
    eventId
}: Props): JSX.Element {
    const dispatch = useAppDispatch();
    const { popupTimeout } = useAppSelector(({ publicEvent }) => publicEvent);
    const { onError } = useShowToast();
    const [isOpenPopup, setIsOpenPopup] = useState(!eventId
        ? true
        : !isUndefined(popupTimeout)
            ? moment().isAfter(moment(popupTimeout, 'x'))
            : true
    );
    const [isProcessing, setIsProcessing] = useState(false);
    const onGenerateDynamicLink = useGenerateDynamicLink();
    const { onRedirectUrl } = useRedirectSocialMedia();
    const { branding, thumbnail } = useBranding();
    const { app, appName } = useHref();

    const onContinueInBrowser = () => {
        setIsOpenPopup(false);
        dispatch(setPopupTimeout(moment().add(1, 'day').valueOf()));

        if (!eventId) {
            dispatch(setIsShowedOpenAppPopup({ isShowedOpenAppPopup: true }));
        }
    };

    const onOpenApp = async () => {
        try {
            setIsProcessing(true);
            const link = eventId ? `${branding?.webAppUrl}public-events?app=${app}&id=${eventId}` : branding?.webAppUrl;
            const generatedLink = await onGenerateDynamicLink(link);

            if (!eventId) {
                dispatch(setIsShowedOpenAppPopup({ isShowedOpenAppPopup: true }));
            }

            onRedirectUrl(generatedLink);
        } catch {
            onError('Unable to open app');
        } finally {
            setIsProcessing(false);
        }
    };

    if (!isMobile) {
        return <></>;
    }

    return (
        <PublicEventBrowserPopup
            className={className}
            logo={thumbnail}
            isOpenPopup={isOpenPopup}
            onOpenApp={onOpenApp}
            isProcessingOpenApp={isProcessing}
            onContinueInBrowser={onContinueInBrowser}
            app={app}
            appName={appName} />
    );
}
