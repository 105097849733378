/* eslint-disable camelcase */
import API from './api';
import { services } from '~/config/constants';
import { ArticleUpdate, Faq, FileType } from '~/types';
import { isEmpty } from 'lodash';

const { coreService } = services;

export const getArticles = async (id: string, groupId: string, type: 'articles' | 'news') => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/${type}`
        }
    );
};

export const getArticle = async (id: string, groupId: string, articleId: string, type: 'articles' | 'news') => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/${type}/${articleId}`
        }
    );
};

export const updateArticle = async (id: string, groupId: string, articleIid: string, data: ArticleUpdate, type: 'articles' | 'news', isVoiceNote?: boolean) => {
    if (isEmpty(data.image)) delete data.image;

    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/${type}/${articleIid}${isVoiceNote ? '?recorded=true' : ''}`,
            method: 'PUT',
            data
        }
    );
};

export const createArticle = async (id: string, groupId: string, data: ArticleUpdate, type: 'articles' | 'news', isVoiceNote?: boolean) => {
    if (isEmpty(data.image)) delete data.image;

    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/${type}${isVoiceNote ? '?recorded=true' : ''}`,
            method: 'POST',
            data
        }
    );
};

export const deleteArticle = async (id: string, groupId: string, articleId: string, type: 'articles' | 'news') => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/${type}/${articleId}`,
            method: 'DELETE'
        }
    );
};

export const saveArticleFile = async (id: string, groupId: string, type: 'news' | 'articles', articleId: string, files: FileType[]) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/manage/${type}/${articleId}/files`,
            method: 'POST',
            data: {
                files
            }
        }
    );
};

export const publicArticle = async (articleId: string) => {
    return await API({
        endpoint: `${coreService}/public/article/${articleId}`,
        method: 'GET'
    });
};

export const addArticleFaq = async (id: string, articleId: string, question: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/articles/${articleId}/faq`,
            method: 'POST',
            data: {
                question,
                question_by: id
            }
        }
    );
};

export const updateArticleFaq = async (id: string, articleId: string, questions: Faq[]) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/articles/${articleId}/faq`,
            method: 'PUT',
            data: {
                questions
            }
        }
    );
};

export const getArticleFaq = async (id: string, articleId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/articles/${articleId}/faq`
        }
    );
};

export const uploadTextEditorImage = async (type_id: string, image: string, onUploadProgress?: (event: any) => void) => {
    return await API(
        {
            endpoint: `${coreService}/admin/upload-image`,
            method: 'POST',
            data: {
                image,
                type_id
            },
            onUploadProgress
        }
    );
};
