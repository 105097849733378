import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EventOptionState = {
    isUpComing?: boolean,
};

const initialState: EventOptionState = {
    isUpComing: true
};

export const eventOptionSlice = createSlice({
    name: 'eventOption',
    initialState,
    reducers: {
        toggleIsUpComing: (state, action: PayloadAction<EventOptionState>) => {
            state.isUpComing = action.payload.isUpComing;
        },
        resetIsUpComing: (state) => {
            state.isUpComing = true;
        }
    }
});

export const { toggleIsUpComing, resetIsUpComing } =
    eventOptionSlice.actions;

export default eventOptionSlice.reducer;
