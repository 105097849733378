import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorBoundary from './ErrorBoundary';
import Storage from '~/utils/Storage';
import { store } from '~/redux/store';
import { logError } from '~/api/logging';

type Props = {
    children?: ReactNode;
}

type State = {
    hasError: boolean;
}

class ErrorBoundaryContainer extends Component<Props, State> {
    public static getDerivedStateFromError(_: Error) {
        return { hasError: true };
    }

    public state: State = {
        hasError: false
    };

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const screen = window.location.pathname?.substring(1);

        const data = {
            message: `${Storage.getItem('apiRequest')} ${Storage.getItem('apiError')} ${error.message}`,
            email: store.getState().profile.email,
            user_id: store.getState().profile.id
        };

        logError(screen, data);
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorBoundary />;
        }

        return this.props.children;
    }
}

export default ErrorBoundaryContainer;
