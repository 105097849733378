import React, { RefObject } from 'react';
import { TypographySize, Color, Weight } from '~/types';
import './typography.scss';
import { removeHTMLElement } from '~/utils/helpers';

type Props = {
    className?: string,
    component?: string,
    children: any,
    color?: Color,
    size?: TypographySize,
    weight?: Weight,
    props?: any,
    numberOfLines?: number,
    customRef?: RefObject<HTMLParagraphElement>,
    selectable?: boolean,
}

export default function Typography({
    component,
    className = '',
    color = 'dark',
    size = 16,
    weight = 'regular',
    children,
    numberOfLines,
    customRef,
    selectable = true,
    ...props
}: Props): JSX.Element {
    const Component = component ? 'span' : 'p';

    return (
        <Component {...props} ref={customRef} className={`typography size-${size} color-${color} font-${weight} ${!selectable ? 'not-selectable' : ''} ${className}`}
            style={{ WebkitLineClamp: numberOfLines, overflow: numberOfLines ? 'hidden' : 'none' }} >
            {typeof children === 'string' ? removeHTMLElement(children) : children}
        </Component>
    );
}
