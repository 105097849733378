/* eslint-disable camelcase */
import { useEffect } from 'react';
import { onGetIdentityUpdated } from '~/api/profile';
import Button from '~/components/base/Button';
import Typography from '~/components/base/Typography';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { clearModalContent, setModalContent } from '~/redux/modules/modalEvent';
import useShowToast from './useShowToast';
import useWebHistory from './useWebHistory';

const useIdentityUpdated = () => {
    const { id, isAuthed } = useAppSelector(({ profile, authentication }) => ({ ...profile, ...authentication }));
    const dispatch = useAppDispatch();

    const { onError } = useShowToast();
    const history = useWebHistory();

    const onNavigateToIdentity = () => {
        dispatch(clearModalContent());
        history.replace('/update-profile?update=true');
    };

    const init = async () => {
        if (!isAuthed || !id || window.location.pathname?.includes('update-profile')) return;

        try {
            const { is_updated } = await onGetIdentityUpdated(id);

            if (!is_updated) {
                dispatch(setModalContent({
                    title: 'Update your profile',
                    modalContent: <div>
                        <Typography>
                            Please update your profile to continue using the app.
                        </Typography>
                        <Button
                            label='Update'
                            onClick={onNavigateToIdentity}
                            className='my-4 mx-auto d-block'
                            size='lg' />
                    </div>,
                    disableClose: true
                }));
            }
        } catch (error) {
            onError('Unable to get user status', error);
        }
    };

    useEffect(() => {
        init();
    }, [id, isAuthed]);
};

export default useIdentityUpdated;
