import firebase from 'firebase';
import { useEffect } from 'react';
import { firebaseConfig } from '~/config/constants';
import { useAppDispatch } from '~/redux/hooks';
import { setIsInitializedFirebaseApp } from '~/redux/modules/authentication';
import useFirebaseAuth from './useFirebaseAuth';

const useFirebase = () => {
    const { onSignInAnonymously } = useFirebaseAuth();
    const dispatch = useAppDispatch();

    const init = async () => {
        try {
            firebase.initializeApp(firebaseConfig);

            await onSignInAnonymously();

            dispatch(setIsInitializedFirebaseApp({
                isInitializedFirebase: true
            }));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        init();
    }, []);
};

export default useFirebase;
