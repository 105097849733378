import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PollCreator, PollStatus } from '~/types';

export type PollFilterState = {
    status?: PollStatus;
    creator?: PollCreator;
};

const initialState: PollFilterState = {
    status: 'All poll status',
    creator: 'All creators'
};

export const pollFilterSlice = createSlice({
    name: 'pollFilter',
    initialState,
    reducers: {
        setPollFilter: (state, action: PayloadAction<PollFilterState>) => {
            state.status = action.payload.status;
            state.creator = action.payload.creator;
        },
        clearPollFilterLink: () => ({
            ...initialState
        })
    }
});

export const { setPollFilter, clearPollFilterLink } = pollFilterSlice.actions;

export default pollFilterSlice.reducer;
