import React, { useEffect, useRef } from 'react';
import './svg-icon.scss';

type Props = {
    className?: string;
    Icon: any;
    color?: string;
    size?: number;
};

export default function SVGIcon({
    className = '',
    Icon,
    color = '#292929',
    size = 24
}: Props): JSX.Element {
    const ref = useRef<any>(null);

    useEffect(() => {
        if (ref.current) {
            const paths = ref.current.querySelectorAll('path');

            if (paths.length) {
                paths.forEach((path: any) => {
                    path.setAttribute('style', `fill: ${color}`);
                });
            }
        }
    }, [ref.current]);

    return (
        <div className={`svg-icon ${className}`}>
            <Icon
                ref={(el: any) => {
                    ref.current = el;
                }}
                color={color}
                height={size}
                width={size}/>
        </div>
    );
}
