/* eslint-disable camelcase */
import { useLocation } from 'react-router-dom';
import { logError } from '~/api/logging';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import Storage from '~/utils/Storage';
import useWebHistory from './useWebHistory';
import useClearStates from './useClearStates';
import { setIsInitializedFirebaseApp, setLoggingOut } from '~/redux/modules/authentication';

const useError = () => {
    const location = useLocation();
    const profile = useAppSelector(({ profile }) => profile);

    const dispatch = useAppDispatch();
    const history = useWebHistory();
    const clearStates = useClearStates();

    const onError = async (message: string) => {
        if (message?.includes("object of type 'NoneType' has no len()")) {
            dispatch(setLoggingOut());
            dispatch(setIsInitializedFirebaseApp({
                isInitializedFirebase: false
            }));

            clearStates();
            history.push('/login');

            return;
        }

        const data = {
            message: `${Storage.getItem('apiRequest')} ${Storage.getItem('apiError')} ${message}`,
            email: profile.email,
            user_id: profile.id
        };

        await logError(location.pathname, data);
    };

    return onError;
};

export default useError;
