import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import useGetDeviceId from './useGetDeviceId';
import useIsProcessing from './useIsProcessing';
import useModalConfirm from './useModalConfirm';
import { clearModalContent } from '~/redux/modules/modalEvent';
import CobrowseIO from 'cobrowse-sdk-js';
import { CoBrowseSession } from '~/types';
import { isEmpty } from 'lodash';

const useCoBrowse = () => {
    const { email, firstName } = useAppSelector(({ profile }) => profile);
    const { isAuthed } = useAppSelector(({ authentication }) => authentication);

    const deviceId = useGetDeviceId();
    const [isStarted, setIsStarted] = useState(false);
    const { isProcessing, onSetIsProcessing } = useIsProcessing();
    const dispatch = useAppDispatch();
    const [session, setSession] = useState<CoBrowseSession>();

    const onConfirmStop = useModalConfirm({
        title: `${session?.state === 'active' ? 'Stop' : 'Cancel'} Session`,
        description: `Are you sure you want to ${session?.state === 'active' ? 'stop' : 'cancel'} the session?`,
        save: {
            title: 'Yes',
            onClick: () => {
                CobrowseIO.stop();
                onStopCoBrowse();
            }
        },
        cancel: {
            title: 'No',
            onClick: () => {
                dispatch(clearModalContent());
            }
        }
    });

    const onStartCoBrowse = async () => {
        try {
            onSetIsProcessing(true);

            // if (email) {
            //     CobrowseIO.customData = {
            //         user_name: firstName,
            //         user_email: email,
            //         device_id: deviceId
            //         // device_name: name
            //     };
            //     CobrowseIO.start();
            //     setIsStarted(true);
            //     onSetIsProcessing(false);

            //     return;
            // }

            CobrowseIO.start();

            await CobrowseIO.client().then(async (client: any) => {
                const session = await CobrowseIO.createSession();

                if (session) {
                    const formattedSession = onGetSession(session);

                    setSession(formattedSession);
                    setIsStarted(true);
                    onSetIsProcessing(false);
                }
            });
        } catch (error: any) {
            console.log('Failed to start session, please try again later.', error);
            const message = error?.message || error;

            if (message === 'Already in a session') {
                setIsStarted(true);
            }

            onSetIsProcessing(false);
        }
    };

    const onStopCoBrowse = () => {
        setIsStarted(false);
        setSession(undefined);
    };

    const onHasSession = () => {
        const endSessionElem = document.getElementsByClassName('cbio_session_controls');

        if (!isEmpty(endSessionElem)) {
            setIsStarted(true);
            setSession({
                state: 'active'
            });
        }
    };

    const onGetSession = (session: any): CoBrowseSession | undefined => {
        if (!session) return;

        return {
            agent: session?.agent(),
            code: session?.code(),
            state: session?.state()
        };
    };

    const onRegisterUser = () => {
        if (isAuthed) {
            CobrowseIO.customData = {
                user_name: firstName,
                user_email: email,
                device_id: deviceId
            };

            CobrowseIO.start();
        }
    };

    const onRemoveUser = () => {
        if (!isAuthed) {
            CobrowseIO.customData = {
                user_name: '',
                user_email: '',
                device_id: ''
            };
        }
    };

    useEffect(() => {
        CobrowseIO.addListener('session.ended', () => {
            onStopCoBrowse();
        });
        CobrowseIO.addListener('session.updated', (session: any) => {
            const formattedSession = onGetSession(session);

            setSession(formattedSession);
            // onDispatchSession(formattedSession);
        });
    }, []);

    useEffect(() => {
        onHasSession();
    }, []);

    useEffect(() => {
        CobrowseIO.license = process.env.REACT_APP_CO_BROWSE_LISCENSE_KEY;
    }, [process.env.REACT_APP_CO_BROWSE_LISCENSE_KEY]);

    useEffect(() => {
        onRegisterUser();
    }, [isAuthed, firstName, email, deviceId, CobrowseIO.license]);

    useEffect(() => {
        onRemoveUser();
    }, [isAuthed, CobrowseIO.license]);

    return {
        isStarted,
        isProcessing,
        onStartCoBrowse,
        onStopCoBrowse: onConfirmStop,
        session,
        hasEmail: !!email && !!isAuthed
    };
};

export default useCoBrowse;
