import React, { useEffect } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import Button from '~/components/base/Button';
import { ModalSize } from '~/types';
import Typography from '../Typography';
import close from '~/assets/icons/close-circle.svg';
import './modal.scss';

type Props = {
    title?: string | JSX.Element,
    children?: string | JSX.Element,
    onToggle?: () => void,
    className?: string,
    size?: ModalSize,
    buttonLabel?: string,
    onClick?: () => void,
    isShow?: boolean,
    disableClose?: boolean
}

export default function Modal({ title, children, onToggle, className = '', size = 'sm', buttonLabel, onClick, isShow, disableClose = false }: Props): JSX.Element {
    const [show, setShow] = React.useState(isShow);

    useEffect(() => {
        setShow(isShow);
    }, [isShow]);

    const handleClose = () => {
        onToggle && onToggle();

        setShow(!show);
    };

    return (
        <BootstrapModal
            className={`modal ${className}`}
            size={size}
            show={show}
            centered={true}
            onHide={!disableClose ? handleClose : () => { }}>
            <BootstrapModal.Body>
                {
                    !!title &&
                    <div className='modal__header'>
                        <>
                            {typeof title === 'string'
                                ? <Typography size={20}
                                    weight='semi-bold' color='neutral-500'>
                                    {title}
                                </Typography>
                                : title}
                            {!disableClose && (
                                <span
                                    className='cursor-pointer'
                                    onClick={handleClose}>
                                    <img src={close} alt='close' />
                                </span>
                            )}
                        </>
                    </div>
                }
                <div className={'modal-children'}>
                    {
                        typeof children === 'string'
                            ? <Typography className='modal-children__content' size={16} color='neutral-400'>
                                {children}
                            </Typography>
                            : children
                    }
                </div>
                {buttonLabel && (
                    <div className={'modal-button-wrapper'}>
                        <Button variant='tertiary' size='lg' label={buttonLabel}
                            onClick={onClick || handleClose} />
                    </div>
                )}
            </BootstrapModal.Body>
        </BootstrapModal>
    );
}
