import API from './api';
import { services } from '~/config/constants';

const { coreService } = services;

export const saveChatImages = async (id: string, images: string[]) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/chat/images`,
            method: 'POST',
            data: {
                images
            }
        }
    );
};

export const getChats = async (id: string, limit: number = 50, start?: string, changes?: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/chat/list?limit=${limit}&start=${start || ''}&changes=${changes || ''}`,
            method: 'GET'
        }
    );
};

export const getMessages = async (id: string, chatId: string, limit: number = 50, start?: string, changes?: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/chat/messages?chat_id=${chatId}&limit=${limit}&start=${start || ''}&changes=${changes || ''}`,
            method: 'GET'
        }
    );
};
