import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalSize } from '../../types';
import { isUndefined } from 'lodash';

export type ModalState = {
    title?: string | JSX.Element,
    modalContent: string | JSX.Element,
    onToggle?: () => void,
    className?: string,
    size?: ModalSize,
    disableClose?: boolean,
}

const initialState: ModalState = {
    title: '',
    modalContent: '',
    className: '',
    size: 'sm',
    onToggle: () => { },
    disableClose: false
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setModalContent: (state, action: PayloadAction<ModalState>) => ({
            ...initialState,
            ...action.payload
        }),
        clearModalContent: () => ({
            ...initialState
        })
    }
});

export const { setModalContent, clearModalContent } = modalSlice.actions;

export default modalSlice.reducer;
