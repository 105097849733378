import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { setFirebaseAnonymousUser } from '~/redux/modules/authentication';
import firebase from 'firebase';
import { isEmpty } from 'lodash';

const useFirebaseAuth = () => {
    const dispatch = useAppDispatch();
    const { firebaseAnonymousUser } = useAppSelector(({ authentication }) => authentication);

    const onSignInAnonymously = async () => {
        try {
            if (!isEmpty(firebaseAnonymousUser)) return firebaseAnonymousUser;
            const resp = await firebase.auth().signInAnonymously();

            dispatch(setFirebaseAnonymousUser({
                firebaseAnonymousUser: resp
            }));

            console.log(resp);

            return resp;
        } catch (error) {
            console.log('Error anonymous sign in', error);
        }
    };

    const onDeleteFirebaseAccount = async () => {
        try {
            return await firebase.auth().currentUser?.delete();
        } catch (error) {
            console.log('Error deleting account', error);
        }
    };

    return {
        onSignInAnonymously,
        onDeleteFirebaseAccount
    };
};

export default useFirebaseAuth;
