export const colors = {
    info: '#698BB4',
    warning: '#AFA47A',
    green: '#37A187',
    dark: '#464646',
    light: '#EFEFEF',
    error: '#ED5B58',
    verySadBg: '#FFDBD6',
    sadBg: '#FFE9DC',
    fineBg: '#FFEEC0',
    happyBg: '#DEFFF0',
    veryHappyBg: '#E7FAFF',
    verySad: '#F15139',
    sad: '#FF9C00',
    fine: '#FBBA18',
    happy: '#56D198',
    veryHappy: '#54C9EA',
    border: '#E9E9E9',
    'davy-grey-50': '#F2F3F1',
    'davy-grey-100': '#E5E7E4',
    'davy-grey-200': '#CCCFC9',
    'davy-grey-300': '#B2B7AE',
    'davy-grey-400': '#98A092',
    'davy-grey-500': '#545A4F',
    'davy-grey-600': '#4C5148',
    'davy-grey-700': '#3D413A',
    'davy-grey-800': '#333630',
    'davy-grey-900': '#191B18',
    'stone-50': '#F5F4EF',
    'stone-100': '#ECE9DF',
    'stone-200': '#D9D4BF',
    'stone-300': '#C6BE9F',
    'stone-400': '#B7AD86',
    'stone-500': '#AFA47A',
    'stone-600': '#7F754D',
    'stone-700': '#605839',
    'stone-800': '#403B26',
    'stone-900': '#201D13',
    'neutral-0': '#FFFFFF',
    'neutral-50': '#F2F2F2',
    'neutral-100': '#D5D5D5',
    'neutral-200': '#B3B3B3',
    'neutral-300': '#818181',
    'neutral-400': '#575757',
    'neutral-500': '#292929',
    'neutral-600': '#222222',
    'neutral-700': '#1F1F1F',
    'neutral-800': '#121212',
    'neutral-900': '#000000',
    'neutral-1000': '#CCCCCC',
    'red-50': '#f8e2e2',
    'red-100': '#f2b3b3',
    'red-200': '#eb8080',
    'red-300': '#e34c4c',
    'red-400': '#db2323',
    'red-500': '#c71414',
    'red-600': '#ae1212',
    'red-700': '#961010',
    'red-800': '#7e0d0d',
    'red-900': '#670b0b',
    'red-1000': '#4f0808',
    'rowing-blue-50': '#e2e7f2',
    'rowing-blue-100': '#b3c1db',
    'rowing-blue-200': '#809ac3',
    'rowing-blue-300': '#4c72ab',
    'rowing-blue-400': '#234f94',
    'rowing-blue-500': '#002147',
    'rowing-blue-600': '#001d3f',
    'rowing-blue-700': '#001937',
    'rowing-blue-800': '#00152f',
    'rowing-blue-900': '#001027',
    'rowing-blue-1000': '#000c1f'
};
