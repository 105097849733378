import firebase from 'firebase';
import { WhereFilterOp } from '~/types';
import { onRemoveUndefinedOrNullFromObject } from './helpers';

class FireStore {
    private collection: string;
    private store: any;

    constructor(collection: string) {
        this.collection = collection;
        this.store = firebase.firestore;
    }

    async add(data: any) {
        try {
            return await this.store()
                .collection(this.collection)
                .add(onRemoveUndefinedOrNullFromObject(data));
        } catch (error) {
            console.log(`Error adding ${this.collection}:`, error);
        }
    }

    async update(doc: string, data: any) {
        try {
            const newData = onRemoveUndefinedOrNullFromObject(data);

            return await this.store()
                .collection(this.collection)
                .doc(doc)
                .update(newData);
        } catch (error) {
            console.log(`Error update ${this.collection}:`, error);
        }
    }

    async getCollections(path?: string, condition?: WhereFilterOp, value?: any) {
        try {
            if (path && condition && value) {
                return await this.getCollectionsWithFilter(path, condition, value);
            }

            return await this.store()
                .collection(this.collection)
                .get();
        } catch (error) {
            console.log(`Error get collections ${this.collection}:`, error);
        }
    }

    async getDoc(doc: string) {
        try {
            return await this.store()
                .collection(this.collection)
                .doc(doc).get();
        } catch (error) {
            console.log(`Error get doc ${this.collection}:`, error);
        }
    }

    async getRef(doc: string) {
        try {
            return await this.store()
                .collection(this.collection)
                .doc(doc);
        } catch (error) {
            console.log(`Error get ref ${this.collection}:`, error);
        }
    }

    async getRefWithFilter(path: string, condition: WhereFilterOp, value: any) {
        try {
            return await this.store()
                .collection(this.collection)
                .where(path, condition, value)
                .get();
        } catch (error) {
            console.log(`Error get ref with filter ${this.collection}:`, error);
        }
    }

    async getCustomCollectionByDoc(collection: string, doc: string) {
        try {
            return await this.store()
                .collection(collection)
                .doc(doc).get();
        } catch (error) {
            console.log(`Error get doc ${collection}:`, error);
        }
    }

    private async getCollectionsWithFilter(path: string, condition: WhereFilterOp, value: any) {
        try {
            return await this.store()
                .collection(this.collection)
                .where(path, condition, value)
                .get();
        } catch (error) {
            console.log(`Error get collections ${this.collection}:`, error);
        }
    }

    getServerTime = () => {
        return firebase.firestore.FieldValue.serverTimestamp();
    };

    getStore() {
        return this.store();
    }

    getConvertedTimeStampToServerTimeStamp(timeStamp: number) {
        return this.store().Timestamp.fromDate(new Date(timeStamp));
    }

    getFieldPath() {
        return this.store.FieldPath;
    }
}

export default FireStore;
