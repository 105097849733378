import { db } from '~/dexie/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { GroupSupportCache } from '~/dexie/dexieTypes';
import { getSupports } from '~/api/groups';
import { useAppSelector } from '~/redux/hooks';

const useGroupSupport = (groupId?: string) => {
    const { id } = useAppSelector(({ profile }) => ({
        ...profile
    }));

    const onRefetch = async () => {
        const support = await onGetGroupSupportArticles();

        onUpdateGroupSupport({
            groupId,
            support
        });
    };

    const onGetGroupSupportArticles = async () => {
        let response: any;

        try {
            response = await getSupports(id!, groupId!, true);
        } catch (error) {
            if (error === 'Error: Invalid group id') {
                response = await getSupports(id!, groupId!, false);
            }
        }

        return response?.response;
    };

    const groupSupport = useLiveQuery(async () => {
        try {
            if (groupId) {
                const groupSupport = await db.groupSupport
                    .where('groupId')
                    .equals(groupId)
                    .toArray();

                if (groupSupport && groupSupport[0]) return groupSupport[0];
            }
        } catch (error) {
            console.log(error);
        }
    }, [groupId]);

    const onUpdateGroupSupport = async (groupSupport: GroupSupportCache) => {
        try {
            const id = await db.groupSupport.put(groupSupport);

            return id;
        } catch (error) {
            console.log(error);
        }
    };

    const onClearGroupSupport = () => {
        try {
            db.groupSupport.clear();
        } catch (error) {
            console.log(error);
        }
    };

    const onGetArticle = (articleId: string) => {
        return groupSupport?.support?.filter?.(article => (article?._id === articleId))?.[0];
    };

    return {
        groupSupport,
        onUpdateGroupSupport,
        onClearGroupSupport,
        onRefetch,
        onGetArticle
    };
};

export default useGroupSupport;
