import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EventPaymentDetailsState = {
    paymentLink?: string,
    paymentCustomText?: string
};

const initialState: EventPaymentDetailsState = {
    paymentLink: undefined,
    paymentCustomText: undefined
};

export const eventPaymentDetailsSlice = createSlice({
    name: 'eventPaymentDetails',
    initialState,
    reducers: {
        setEventPaymentDetails: (state, action: PayloadAction<EventPaymentDetailsState>) => {
            state.paymentLink = action.payload.paymentLink;
            state.paymentCustomText = action.payload.paymentCustomText;
        },
        clearEventPaymentDetails: () => ({
            ...initialState
        })
    }
});

export const { setEventPaymentDetails, clearEventPaymentDetails } = eventPaymentDetailsSlice.actions;

export default eventPaymentDetailsSlice.reducer;
