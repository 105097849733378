/* eslint-disable camelcase */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import type { Chat, Message, MessageChatPositionUpdate, OpenedChat } from '~/types';

export type MessageState = {
    replyToMessage?: Message,
    chats?: Chat[],
    messages?: any,
    chatsLatestMessage?: any,
    members?: any,
    limit?: any,
    isMaxLimit?: any,
    isFetchingMessages?: any,
    tempMessages?: any,
    messageConnections?: any[],
    messageDrafts?: any,
    messageGroups?: any[],
    messageFilter?: {
        messageType: string,
        groupChatType: string
    },
    openedChat?: OpenedChat,
    isNewChat?: boolean,
    pendingPolls?: any,
    polls?: any,
    position?: MessageChatPositionUpdate,
    is_updated_messages?: boolean,
    is_updated_chats?: boolean
};

const initialState: MessageState = {
    replyToMessage: undefined,
    messages: {},
    chats: [],
    chatsLatestMessage: undefined,
    members: {},
    limit: {},
    isMaxLimit: {},
    isFetchingMessages: {},
    tempMessages: {},
    messageConnections: [],
    messageDrafts: {},
    messageGroups: [],
    messageFilter: {
        messageType: 'All Chats',
        groupChatType: 'All Group Chat Types'
    },
    openedChat: {
        chatId: undefined,
        title: undefined,
        type: undefined,
        uri: undefined,
        memberId: undefined,
        groupId: undefined,
        chat: undefined,
        isFromNotif: false
    },
    pendingPolls: {},
    polls: {},
    position: undefined,
    is_updated_messages: false,
    is_updated_chats: false
};

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setReplyToMessage: (state, action: PayloadAction<MessageState>) => {
            state.replyToMessage = action.payload.replyToMessage;
        },
        clearReplyToMessage: (state) => {
            state.replyToMessage = undefined;
        },
        setMessages: (state, action: PayloadAction<MessageState>) => {
            if (isUndefined(action.payload.messages)) return;

            const chatId = Object.keys(action.payload.messages)[0];
            const messages = action.payload.messages[chatId] || [];
            const position = action.payload.position;

            if (isEmpty(state.messages[chatId]) || !state.is_updated_messages || position === 'ALL') {
                state.messages[chatId] = messages;
                state.is_updated_messages = true;

                return;
            }

            if (messages && messages.length > 1) {
                if (state.messages[chatId] && state.messages[chatId]?.length > 0) {
                    messages?.map((msg: any) => {
                        const msgDate = moment.utc(msg?.date);

                        const index = state.messages[chatId]?.findIndex((c: any) => c?.id === msg?.id || (c?.localId && c?.localId === msg?.localId));

                        if (index === -1 || isUndefined(index)) {
                            const firstDate = moment.utc(state.messages[chatId][0]?.date);

                            if (msgDate.isAfter(firstDate)) {
                                state.messages[chatId]?.unshift(msg);
                            } else {
                                state.messages[chatId]?.push(msg);
                            }
                        } else if (index > -1 && state.messages[chatId]) {
                            state.messages[chatId][index] = msg;
                        }
                    });

                    return;
                }

                state.messages[chatId] = messages;

                return;
            }

            const message = messages[0];
            const index = state.messages[chatId]?.findIndex((m: any) => m?.id === message?.id || (m?.localId && m?.localId === message?.localId));

            if (index > -1) {
                state.messages[chatId][index] = message;

                return;
            }

            if (index === -1 || isUndefined(index)) {
                state.messages[chatId]?.unshift(message);
            }
        },
        setChats: (state, action: PayloadAction<MessageState>) => {
            const position = action.payload.position;

            if (isEmpty(state.chats) || !state.is_updated_chats || position === 'ALL') {
                state.chats = action.payload.chats;
                state.is_updated_chats = true;

                return;
            }

            if (action.payload?.chats && action.payload?.chats?.length > 1) {
                if (state.chats && state.chats?.length > 0) {
                    action.payload.chats?.map((chat: any) => {
                        const index = state.chats?.findIndex((c: any) => c?.id === chat?.id || (c?.localId && c?.localId === chat?.localId));

                        if (index === -1 || isUndefined(index)) {
                            state.chats?.push(chat);
                        } else if (index > -1 && state.chats) {
                            const chatDate = moment.utc(chat?.updated_date);
                            const firstChatDate = moment.utc(state.chats[0]?.updated_date);

                            if (chatDate.isAfter(firstChatDate)) {
                                state.chats.splice(index, 1);
                                state.chats.unshift(chat);

                                return;
                            }

                            state.chats[index] = chat;
                        }
                    });

                    return;
                }

                state.chats = action.payload.chats;

                return;
            }

            const chats = current(state.chats);

            const chat = action.payload.chats?.[0];

            if (chat && chats && state.chats) {
                const index = chats?.findIndex((c: any) => c?.id === chat?.id || (c?.localId && c?.localId === chat?.localId));

                if (index === 0) {
                    state.chats[0] = chat;

                    return;
                }

                if (index > 0) {
                    const chatDate = moment.utc(chat?.updated_date);
                    const firstChatDate = moment.utc(state.chats[0]?.updated_date);

                    if (chatDate.isAfter(firstChatDate)) {
                        state.chats.splice(index, 1);
                        state.chats.unshift(chat);

                        return;
                    }

                    state.chats[index] = chat;

                    return;
                }

                if (index === -1 || isUndefined(index)) {
                    state.chats?.push(chat);
                }
            }
        },
        setChatLatestMessage: (state, action: PayloadAction<MessageState>) => {
            state.chatsLatestMessage = action.payload.chatsLatestMessage;
        },
        setMembers: (state, action: PayloadAction<MessageState>) => {
            state.members = action.payload.members;
        },
        setLimit: (state, action: PayloadAction<MessageState>) => {
            state.limit = action.payload.limit;
        },
        setIsMaxLimit: (state, action: PayloadAction<MessageState>) => {
            state.isMaxLimit = action.payload.isMaxLimit;
        },
        setIsFetchingMessages: (state, action: PayloadAction<MessageState>) => {
            state.isFetchingMessages = action.payload.isFetchingMessages;
        },
        setTempMessages: (state, action: PayloadAction<MessageState>) => {
            state.tempMessages = action.payload.tempMessages;
        },
        setMessageConnections: (state, action: PayloadAction<MessageState>) => {
            state.messageConnections = action.payload.messageConnections;
        },
        setMessageDrafts: (state, action: PayloadAction<MessageState>) => {
            state.messageDrafts = action.payload.messageDrafts;
        },
        setMessageGroups: (state, action: PayloadAction<MessageState>) => {
            state.messageGroups = action.payload.messageGroups;
        },
        setMessageFilter: (state, action: PayloadAction<MessageState>) => {
            state.messageFilter = action.payload.messageFilter;
        },
        setOpenedChat: (state, action: PayloadAction<MessageState>) => {
            state.openedChat = action.payload.openedChat;
        },
        clearOpenedChat: (state) => {
            state.openedChat = undefined;
        },
        clearMessageFilter: (state) => {
            state.messageFilter = {
                messageType: 'All Chats',
                groupChatType: 'All Group Chat Types'
            };
        },
        setIsNewChat: (state, action: PayloadAction<MessageState>) => {
            state.isNewChat = action.payload.isNewChat;
        },
        setPendingPolls: (state, action: PayloadAction<MessageState>) => {
            state.pendingPolls = action.payload.pendingPolls;
        },
        setPolls: (state, action: PayloadAction<MessageState>) => {
            state.polls = action.payload.polls;
        },
        clearMessages: () => ({
            ...initialState
        })
    }
});

export const { setReplyToMessage, clearReplyToMessage, setMessages, setChats, setChatLatestMessage, setMembers, setLimit, setIsMaxLimit, setIsFetchingMessages, setTempMessages, setMessageConnections, setMessageDrafts, setMessageGroups, setMessageFilter, clearMessageFilter, setOpenedChat, clearMessages, setIsNewChat, clearOpenedChat, setPendingPolls, setPolls } = messageSlice.actions;

export default messageSlice.reducer;
