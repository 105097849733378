/* eslint-disable camelcase */
import API from './api';
import { services } from '~/config/constants';

const { coreService } = services;

export const logError = async (screen: string, data: {
    message?: string,
    email?: string,
    user_id?: string,
}) => {
    if (data.user_id) {
        delete data.email;
    } else {
        delete data.user_id;
    }

    if (data.email) {
        data.email = data.email.toLowerCase();
    }

    if (screen) {
        const splitted = screen?.split('/');

        if (splitted.length > 1) {
            screen = splitted[1];
        }
    }

    return await API(
        {
            endpoint: `${coreService}/${data.user_id ? 'user-id' : 'email'}/${screen === "What's on?" ? 'Events' : screen || 'web'}/logs`,
            method: 'POST',
            data
        }
    );
};
