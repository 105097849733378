import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RegimentsState = {
    regiments?: string[],
};

const initialState: RegimentsState = {
    regiments: undefined
};

export const regimentsSlice = createSlice({
    name: 'regiments',
    initialState,
    reducers: {
        setRegiments: (state, action: PayloadAction<RegimentsState>) => {
            state.regiments = action.payload.regiments;
        }
    }
});

export const { setRegiments } = regimentsSlice.actions;

export default regimentsSlice.reducer;
