import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BadgeState = {
    count?: number,
    isShowedInstruction?: boolean,
};

const initialState: BadgeState = {
    count: 0,
    isShowedInstruction: false
};

export const badgeSlice = createSlice({
    name: 'badge',
    initialState,
    reducers: {
        setBadge: (state, action: PayloadAction<BadgeState>) => {
            state.count = action.payload.count;
        },
        setIsShowedInstructions: (state, action: PayloadAction<BadgeState>) => {
            state.isShowedInstruction = action.payload.isShowedInstruction;
        },
        clearBadge: () => ({
            ...initialState
        })
    }
});

export const { setBadge, setIsShowedInstructions, clearBadge } = badgeSlice.actions;

export default badgeSlice.reducer;
