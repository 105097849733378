import { useAppDispatch } from '~/redux/hooks';
import { setLoggingOut } from '~/redux/modules/authentication';
import { clearBadge } from '~/redux/modules/badge';
import { clearFlashMessage } from '~/redux/modules/flashNotification';
import { clearGroupInfo } from '~/redux/modules/groupInfo';
import { clearMessages } from '~/redux/modules/message';
import { clearProfile } from '~/redux/modules/profile';
import useGroupInfo from './useGroupInfo';
import useGroupNews from './useGroupNews';
import useGroupSupport from './useGroupSupport';
import useArticles from './useArticles';

const useClearStates = () => {
    const dispatch = useAppDispatch();
    const { onClearGroups } = useGroupInfo();
    const { onClearGroupNews } = useGroupNews();
    const { onClearGroupSupport } = useGroupSupport();
    const { onClearArticles } = useArticles();

    const onClear = () => {
        dispatch(setLoggingOut());
        dispatch(clearFlashMessage());
        dispatch(clearBadge());
        dispatch(clearGroupInfo());
        dispatch(clearMessages());
        dispatch(clearProfile());

        onClearGroups();
        onClearGroupNews();
        onClearGroupSupport();
        onClearArticles();
    };

    return onClear;
};

export default useClearStates;
