/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeelingStatus, Question } from '../../types';

export type QuestionnaireState = {
    sliderValue?: number,
    sliderStatus?: FeelingStatus,
    questions?: {
        date_created?: string,
        pinBoard?: Question[],
        pinboard_id?: string

    },
    answers?: any
};

const initialState: QuestionnaireState = {
    sliderValue: 50,
    sliderStatus: 'Fine',
    questions: {
        date_created: '',
        pinBoard: [],
        pinboard_id: ''
    },
    answers: {}
};

export const questionnaireSlice = createSlice({
    name: 'questionnaire',
    initialState,
    reducers: {
        setFeeling: (state, action: PayloadAction<QuestionnaireState>) => {
            state.sliderValue = action.payload.sliderValue;
            state.sliderStatus = action.payload.sliderStatus;
        },
        setQuestions: (state, action: PayloadAction<QuestionnaireState>) => {
            state.questions = action.payload.questions;
        },
        setAnswers: (state, action: PayloadAction<QuestionnaireState>) => {
            state.answers = action.payload.answers;
        },
        clearQuestionnaire: () => ({
            ...initialState
        })
    }
});

export const { setFeeling, setQuestions, setAnswers, clearQuestionnaire } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
