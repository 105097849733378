import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MembersProfileState = {
    membersProfile?: any
};

const initialState: MembersProfileState = {
    membersProfile: {}
};

export const membersProfileSlice = createSlice({
    name: 'membersProfile',
    initialState,
    reducers: {
        setMembersProfile: (state, action: PayloadAction<MembersProfileState>) => {
            state.membersProfile = action.payload.membersProfile;
        }
    }
});

export const { setMembersProfile } =
    membersProfileSlice.actions;

export default membersProfileSlice.reducer;
