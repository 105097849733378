import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EventsState = {
    allGroupEvents?: any[]
};

const initialState: EventsState = {
    allGroupEvents: []
};

export const groupRolesSlice = createSlice({
    name: 'groupRoles',
    initialState,
    reducers: {
        setAllGroupEvents: (state, action: PayloadAction<EventsState>) => {
            state.allGroupEvents = action.payload.allGroupEvents;
        }
    }
});

export const { setAllGroupEvents } = groupRolesSlice.actions;

export default groupRolesSlice.reducer;
