import { useState } from 'react';

const useIsProcessing = () => {
    const [isProcessing, setIsProcessing] = useState(false);

    const onSetIsProcessing = (value: boolean) => {
        setIsProcessing(value);
    };

    return {
        isProcessing,
        onSetIsProcessing
    };
};

export default useIsProcessing;
