import React from 'react';
import './page-error.scss';
import Typography from '~/components/base/Typography';
import Button from '~/components/base/Button';
import BackgroundWrapper from '../BackgroundWrapper';

type Props = {
    className?: string,
    onReload: () => void
}

export default function PageError({ className = '', onReload }: Props): JSX.Element {
    return (
        <BackgroundWrapper>
            <div className={`page-error ${className}`} >
                <div className='page-error__wrapper'>
                    <Typography size={18} weight='semi-bold' color='light'
                        className='page-error__wrapper-title'>
                        Sorry, there was a problem loading the page.
                    </Typography>
                    <Button
                        label='Reload'
                        onClick={onReload} />
                </div>
            </div>
        </BackgroundWrapper>
    );
}
