import { db } from '~/dexie/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { ArticlesCache } from '~/dexie/dexieTypes';
import { useAppSelector } from '~/redux/hooks';
import { getArticles } from '~/api/articles';

const useArticles = (groupId?: string, articleType?: 'articles' | 'news') => {
    const { id } = useAppSelector(({ profile }) => ({
        ...profile
    }));

    const onRefetch = async () => {
        const articles = await onGetArticles();

        onUpdateArticles(articles);
    };

    const onGetArticles = async () => {
        let response: any;

        try {
            response = await getArticles(
                id!,
                groupId!,
                articleType!
            );
        } catch (error) {
            console.log(error);
        }

        return response?.articles || response?.response;
    };

    const articles = useLiveQuery(async () => {
        try {
            const articles = await db.articles.toArray();

            return articles;
        } catch (error) {
            console.log(error);
        }

        return [];
    }, [articleType]);

    const onUpdateArticle = async (article: ArticlesCache) => {
        try {
            const id = await db.articles.put(article);

            return id;
        } catch (error) {
            console.log(error);
        }
    };

    const onUpdateArticles = async (articles: ArticlesCache[]) => {
        try {
            const id = await db.articles.bulkPut(articles);

            return id;
        } catch (error) {
            console.log(error);
        }
    };

    const onClearArticles = async () => {
        try {
            await db.articles.clear();
        } catch (error) {
            console.log(error);
        }
    };

    const onGetArticle = (articleId: string) => {
        return articles?.filter?.(article => (article?._id === articleId))?.[0];
    };

    return {
        articles,
        onUpdateArticle,
        onUpdateArticles,
        onClearArticles,
        onRefetch,
        onGetArticle
    };
};

export default useArticles;
