import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TourBaseState = {
    tours?: any[],
};

const initialState: TourBaseState = {
    tours: []
};

export const tourBaseSlice = createSlice({
    name: 'tourBase',
    initialState,
    reducers: {
        setTours: (state, action: PayloadAction<TourBaseState>) => {
            state.tours = action.payload.tours;
        }
    }
});

export const { setTours } = tourBaseSlice.actions;

export default tourBaseSlice.reducer;
