import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BoatType } from '~/types';

export type BoatTypesState = {
    boatTypes: BoatType[],
};

const initialState: BoatTypesState = {
    boatTypes: []
};

export const boatTypesSlice = createSlice({
    name: 'boatTypes',
    initialState,
    reducers: {
        setBoatTypes: (state, action: PayloadAction<BoatTypesState>) => {
            state.boatTypes = action.payload.boatTypes;
        }
    }
});

export const { setBoatTypes } = boatTypesSlice.actions;

export default boatTypesSlice.reducer;
