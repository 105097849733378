/* eslint-disable camelcase */
import API, { ELASTIC_API_REQUEST } from './api';
import { elasticInfo, services, MA_PINBOARD_ID, API_CONFIG, IS_MA_SUB_GROUP } from '~/config/constants';
import { GroupUpdate, Event, EventFilter, GroupSection, MembersFilter, MultipleEvents, EmbedWebsiteButton, FileChunk, GroupPinboardTile, SubgroupPublishingPermission } from '~/types';
import { isEmpty } from 'lodash';
import { objectToParams } from '~/utils/helpers';

const { coreService } = services;

export const getGroups = async (id: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/view-my-groups-and-other-groups`
        }
    );
};

export const getGroupInfo = async (groupId: string, isMainGroup?: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/${groupId}/${isMainGroup ? 'my-group' : 'subgroup'}-info`
        }
    );
};

export const getAllGroups = async () => {
    return await API(
        {
            endpoint: `${coreService}/groups/main-groups`
        }
    );
};

export const getMemberDetails = async (id: string, memberId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/members-info?member_id=${memberId}`
        }
    );
};

export const joinSubGroup = async (id: string, groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/subgroup/${groupId}/join`,
            method: 'POST'
        }
    );
};

export const isAlreadyRequestedToJoin = async (id: string, groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/group-request`
        }
    );
};

export const cancelJoinRequest = async (id: string, groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/groups/${groupId}/cancel-connection-request`,
            method: 'POST'
        }
    );
};

export const leaveGroup = async (id: string, groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/identity/subgroup/${groupId}/leave`,
            method: 'POST'
        }
    );
};

export const getLatestNews = async (id: string, groupId: string, isSubGroup?: boolean, date_updated?: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/${isSubGroup ? 'sub' : ''}groups/${groupId}/latest-news${date_updated ? `?date_updated=${date_updated}` : ''}`,
            method: 'GET'
        }
    );
};

export const getSupports = async (id: string, groupId: string, isSubGroup?: boolean, article?: string, date_updated?: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/${isSubGroup ? 'sub' : ''}groups/${groupId}/support-articles${article ? `?article_type=${article}` : ''}${date_updated ? `${article ? '&' : '?'}date_updated=${date_updated}` : ''}`,
            method: 'GET'
        }
    );
};

export const getEventsViaGroups = async (id: string, groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/events`
        }
    );
};

export const updateGroup = async (id: string, group_id: string, data: GroupUpdate) => {
    if (isEmpty(data.purpose_image)) delete data.purpose_image;
    if (isEmpty(data.feature_image)) delete data.feature_image;
    if (isEmpty(data.logo)) delete data.logo;
    if (isEmpty(data.purpose_image)) delete data.purpose_image;
    if (isEmpty(data.thumbnail)) delete data.thumbnail;

    return await API({
        endpoint: `${coreService}/${id}/groups/${group_id}`,
        method: 'PUT',
        data
    });
};

export const getGroupAssociate = async (id: string, group_id: string, isSubGroup?: boolean) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${group_id}/${!isSubGroup ? 'subgroups' : 'main-groups'}`
    });
};

export const setGroupAssociate = async (id: string, group_id: string, data: any, isSubgroup?: boolean) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${group_id}/${!isSubgroup ? 'sub' : ''}group`,
        data,
        method: 'POST'
    });
};

export const onGetSubGroupInvites = async (id: string, subGroupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/subgroup/${subGroupId}/invite`
    });
};

export const onGetArticleTypes = async () => {
    return await API({
        endpoint: `${coreService}/db/article-types`
    });
};

export const onGetCategories = async () => {
    return await API({
        endpoint: `${coreService}/db/categories`
    });
};

export const onGetCanBeEventOrganizers = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/can-be-event-organizers`
    });
};

export const onGetGroupsForEventListing = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/as-event-publisher`
    });
};

export const onGetEventQuestionTypes = async () => {
    return await API({
        endpoint: `${coreService}/db/event-answer-types`
    });
};

export const onGetEventTypes = async () => {
    return await API({
        endpoint: `${coreService}/db/event-types`
    });
};

export const onSaveEvent = async (id: string, groupId: string, data: Event, type: 'creator' | 'publisher', eventId?: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/${type}${eventId ? `?event_id=${eventId}` : ''}`,
        data,
        method: 'POST'
    });
};

export const onUpdateEvent = async (id: string, groupId: string, eventId: string, data: Event, type: 'creator' | 'publisher') => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/${eventId}/events/${type}`,
        data,
        method: 'POST'
    });
};

export const onGetEventFromWot = async (id: string, eventId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/events/${eventId}`
    });
};

export const onGetEventFromGroup = async (id: string, groupId: string, eventId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/${eventId}`
    });
};

export const onGetEventsFromPrev = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/lists`
    });
};

export const onUpdateEventViaGroups = async (id: string, groupId: string, eventId: string, data: Event, type: 'creator' | 'publisher') => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/${eventId}/${type}`,
        data,
        method: 'PUT'
    });
};

export const onUpdateEventViaWOT = async (id: string, eventId: string, data: Event, type: 'creator' | 'publisher') => {
    return await API({
        endpoint: `${coreService}/${id}/events/${eventId}/${type}`,
        data,
        method: 'PUT'
    });
};

export const onUpdateEventSoldOutStatus = async (id: string, eventId: string, status: boolean) => {
    return await API({
        endpoint: `${coreService}/${id}/events/${eventId}/sold-out/${status}`,
        method: 'PUT'
    });
};

export const onGetEventRegions = async (id: string) => {
    return await API({
        endpoint: `${coreService}/${id}/events/regions`
    });
};

export const onGetMyEvents = async (id: String) => {
    return await API({
        endpoint: `${coreService}/${id}/my-events`
    });
};

export const onGetEvents = async (id: string, filter: EventFilter = {
    country: '',
    distance: 0,
    event_type: '',
    lat: 0,
    long: 0,
    region: '',
    district: ''
}) => {
    return await API({
        endpoint: `${coreService}/${id}/events?${objectToParams(filter, true)}`
    });
};

export const onGetEventGoing = async (id: string, eventId: string, params: MembersFilter = {
    distance: 0,
    end_work_date: undefined,
    groups: '',
    industry: '',
    lat: 0,
    long: 0,
    service_end_date: undefined,
    service_start_date: undefined,
    start_work_date: undefined,
    work_experience: undefined
}
) => {
    return await API({
        endpoint: `${coreService}/${id}/events/${eventId}/going?${objectToParams(params, true)}`
    });
};

export const onSaveEventCountryOrRegion = async (id: string, filter: string) => {
    return await API({
        endpoint: `${coreService}/${id}/events/filters`,
        method: 'POST',
        data: {
            filter
        }
    });
};

export const onGetEventCountryOrRegion = async (id: string) => {
    return await API({
        endpoint: `${coreService}/${id}/events/filters`
    });
};

export const onSaveEventQuestionAnswer = async (id: string, eventId: string, details: any[]) => {
    return await API({
        endpoint: `${coreService}/${id}/events/reply/${eventId}`,
        method: 'POST',
        data: {
            details
        }
    });
};

export const onUpdateEventQuestionAnswer = async (id: string, eventId: string, details: any[]) => {
    return await API({
        endpoint: `${coreService}/${id}/events/reply/${eventId}`,
        method: 'PUT',
        data: {
            details
        }
    });
};

export const onGetEventQuestionAnswer = async (id: string, eventId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/events/reply/${eventId}`
    });
};

export const onGetReviewEvents = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/review-events`
    });
};

export const onPublishEvent = async (id: string, groupId: string, eventId: string, notification: boolean = true) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/${eventId}/publish/${notification}`,
        method: 'PUT'
    });
};

export const onUnPublishEvent = async (id: string, groupId: string, eventId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/${eventId}/un-publish`,
        method: 'PUT'
    });
};

export const onDownloadEventQuestionResults = async (id: string, eventId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/events/${eventId}/csv`
    });
};

export const onGetAutoJoinStatus = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/manage/auto-join`
    });
};

export const onGetGroupSections = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/manage/group-sections`
    });
};

export const onUpdateGroupSections = async (id: string, groupId: string, groupSections: GroupSection[]) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/manage/group-sections`,
        data: {
            group_sections: groupSections
        },
        method: 'POST'
    });
};

export const sendElasticRequest = async (path: string, body: any, group_id?: string) => {
    return await ELASTIC_API_REQUEST({
        endpoint: `${coreService}/events/hook`,
        data: {
            url: `${elasticInfo.api!}${path!}?app_id=${API_CONFIG.API_ID}${group_id ? `&group_id=${group_id}` : ''}`,
            ...body
        },
        method: 'POST'
    });
};

export const onGetEventICS = async (eventId: string) => {
    return await API({
        endpoint: `${coreService}/calendar/events/${eventId}`
    });
};

export const onGetBlackListedList = async (id: string, groupId: string, params: MembersFilter = {
    distance: 0,
    end_work_date: undefined,
    industry: '',
    lat: 0,
    long: 0,
    service_end_date: undefined,
    service_start_date: undefined,
    start_work_date: undefined,
    work_experience: undefined
}) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/manage/members/black-listed?${objectToParams(params, true)}`
    });
};

export const onGetCreatorsAndPublishersAndMembers = async (id: string, groupId: string, params: MembersFilter = {
    distance: 0,
    end_work_date: undefined,
    industry: '',
    lat: 0,
    long: 0,
    service_end_date: undefined,
    service_start_date: undefined,
    start_work_date: undefined,
    work_experience: undefined,
    search: undefined
}) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/manage/events/users?${objectToParams(params, true)}`
    });
};

export const onUpdateGroupAutoJoin = async (id: string, groupId: string, status: boolean) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/manage/auto-join/${status}`,
        method: 'POST'
    });
};

export const onGetEventQuestionResults = async (id: string, eventId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/events/${eventId}/summary`
    });
};

export const onGetEventMembersInvite = async (id: string, eventId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/events/${eventId}/invite`
    });
};

export const onRemoveMemberEventRole = async (id: string, groupId: string, memberId: string, role: 'creator' | 'publisher') => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/group_admin/event-role`,
        data: {
            user_id: memberId,
            role
        },
        method: 'DELETE'
    });
};

export const onGetCreatorsAndPublishers = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/can-be-event-organizers`
    });
};

export const onUpdateEventRole = async (id: string, groupId: string, users: string[], role: 'creator' | 'publisher', method: 'POST' | 'PUT' = 'POST') => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/group_admin/event-role`,
        data: {
            users,
            role
        },
        method
    });
};

export const onGetAssociatedPrimaryGroups = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/manage/visibility`
    });
};

export const onSetAssociatedPrimaryGroups = async (id: string, groupId: string, groups: string[]) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/manage/visibility`,
        method: 'POST',
        data: {
            groups
        }
    });
};

export const onGetAffiliations = async (id: string) => {
    return await API({
        endpoint: `${coreService}/${id}/identity/regiments`
    });
};

export const onSaveAffiliations = async (id: string, affiliations: any[]) => {
    return await API({
        endpoint: `${coreService}/${id}/identity/regiments`,
        method: isEmpty(affiliations) ? 'DELETE' : 'POST',
        data: {
            affiliations
        }
    });
};

export const onDeleteEvent = async (id: string, eventId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/events/${eventId}`,
        method: 'DELETE'
    });
};

export const onGetMembersAffiliations = async (id: string, memberId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/identity/members/${memberId}/regiments`
    });
};

export const onGetGroupCodes = async () => {
    return await API({
        endpoint: `${coreService}/admin/group-code`
    });
};

export const onUpdateTermsAndConditions = async (body: string) => {
    return await API({
        endpoint: `${coreService}/admin/terms-and-conditions`,
        method: 'POST',
        data: {
            body
        }
    });
};

export const sendImagesRequest = async (path: string) => {
    return await ELASTIC_API_REQUEST({
        endpoint: `${coreService}/events/hook`,
        data: {
            url: path
        },
        method: 'GET'
    });
};

export const convertToBase64 = async (url: string) => {
    return await API({
        endpoint: `${coreService}/image_to_base64`,
        method: 'POST',
        data: {
            url
        }
    });
};

export const onGetEventsMultipleRegions = async (id: string, filter: EventFilter = {
    country: '',
    distance: 0,
    event_type: '',
    lat: 0,
    long: 0,
    region: '',
    district: ''
}) => {
    return await API({
        endpoint: `${coreService}/${id}/events-multiple-regions?${objectToParams(filter, true)}`
    });
};

export const getGroupTaglineAndImage = async (groupName: string) => {
    return await API({
        endpoint: `${coreService}/group-tagline-image?regiment=${groupName}`
    });
};

export const onGetFamilyGroupAndGroupsByGroupCode = (groupCode: string) => {
    return API({
        endpoint: `${coreService}/family-groups/groups?group_code=${groupCode}`
    });
};

export const onSaveMultipleEvent = async (id: string, groupId: string, data: MultipleEvents, eventId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/events/${eventId}/create-from-queue`,
        data,
        method: 'POST'
    });
};

export const getGroupArticleAndMAArticle = async (id: string, groupId: string, excludedMAArticleId?: string) => {
    const query = `?is_subgroup=${IS_MA_SUB_GROUP}${excludedMAArticleId ? `&excluded_ma_article_id=${excludedMAArticleId}` : ''}    `;

    return await API({
        endpoint: `${coreService}/${id}/groups/pinboard/${groupId}/${MA_PINBOARD_ID}${query}`,
        method: 'GET'
    });
};

export const getSubGroupArticle = async (id: string) => {
    return await API({
        endpoint: `${coreService}/${id}/subgroups/pinboard`,
        method: 'GET'
    });
};

export const getSubGroupApprovalRequests = async (id: string) => {
    return await API({
        endpoint: `${coreService}/${id}/subgroups/pinboard/requests`,
        method: 'GET'
    });
};

export const updateGroupWebAppURL = async (id: string, groupId: string, webAppURL: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/manage/web-app-url`,
        method: 'POST',
        data: {
            web_app_url: webAppURL
        }
    });
};

export const getMainGroupInfo = async (id: string) => {
    return await API({
        endpoint: `${coreService}/${id}/identity/main-group`,
        method: 'GET'
    });
};

export const getGroupSectionURL = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/urls-section`,
        method: 'GET'
    });
};

export const updateGroupSectionURLs = async (id: string, groupId: string, websites: EmbedWebsiteButton[]) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/urls-section`,
        method: 'PUT',
        data: {
            websites
        }
    });
};

export const getRegistrationGroupList = async () => {
    return await API({
        endpoint: `${coreService}/admin/registration-group-list`
    });
};

export const getGroupWebURL = async (id: string, groupId: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/${groupId}/web-app-url`,
        method: 'GET'
    });
};

export const getGroupsWhereUserIsAdmin = async (id: string) => {
    return await API({
        endpoint: `${coreService}/${id}/groups/admin`,
        method: 'GET'
    });
};

export const getNewsById = async (id: string, group_id: string, news_id: string) => {
    return await API({
        endpoint: `${coreService}/admin/news/${news_id}?group_id=${group_id}`
    });
};

export const getArticleById = async (id: string, group_id: string, article_id: string, has_group_info?: boolean) => {
    return await API({
        endpoint: `${coreService}/admin/support-article/${article_id}?group_id=${group_id}${has_group_info ? '&has_group_info=true' : ''}`
    });
};

export const saveFileChunks = async (id: string, data: FileChunk) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/group/chunk-file`,
            method: 'POST',
            data
        }
    );
};

export const updateGroupPinboardTitle = async (id: string, groupId: string, data: GroupPinboardTile) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/manage/pinboard-tile`,
            method: 'PUT',
            data
        }
    );
};

export const getGroupPinboardTile = async (id: string, groupId: string, isIncludeGroupInfo?: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/groups/${groupId}/pinboard-tile${isIncludeGroupInfo ? '?is_include_group=true' : ''}`,
            method: 'GET'
        }
    );
};

export const updateSubgroupPublishingPermission = async (id: string, groupId: string, permission: SubgroupPublishingPermission, is_on: boolean) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/group/${groupId}/publishing-permission/${permission}`,
            method: 'PUT',
            data: {
                is_on
            }
        }
    );
};

export const getSubgroupPublishingPermission = async (id: string, groupId: string) => {
    return await API(
        {
            endpoint: `${coreService}/${id}/group/${groupId}/publishing-permission`,
            method: 'GET'
        }
    );
};
