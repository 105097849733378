import { useEffect, useState } from 'react';

const useGetDeviceId = () => {
    const [deviceId, setDeviceId] = useState('');

    const onGetDeviceId = async () => {
        const deviceId = window.navigator.userAgent;

        setDeviceId(deviceId);
    };

    useEffect(() => {
        onGetDeviceId();
    }, []);

    return deviceId;
};

export default useGetDeviceId;
