import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import AppLoading from 'components/modules/AppLoading';
import Modal from 'components/base/Modal';
import FlashNotification from 'components/modules/FlashNotification';
// import Navbar from 'components/base/Navbar';
import ROUTES from 'routes';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { clearModalContent } from 'redux/modules/modalEvent';
import useFirebase from '~/hooks/useFirebase';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'cropperjs/dist/cropper.css';
import useFirebaseNotification from './hooks/useFirebaseNotification';
import useBranding from './hooks/useBranding';
import ScrollToTop from './hooks/useScrollToTop';
import useShowMaintenance from './hooks/useShowMaintenance';
import useChats from './hooks/useChats';
import useCoBrowse from './hooks/useCoBrowse';
import useIdentityUpdated from './hooks/useIdentityUpdated';
import useWebHistory from './hooks/useWebHistory';
import useShowOpenAppPopup from './hooks/useShowOpenAppPopup';
import PublicEventBrowserPopup from './components/modules/PublicEventBrowserPopup';
import useHref from './hooks/useHref';

function App() {
    useFirebase();
    const { isLoading: IS_LOADING_MAINTENANCE } = useShowMaintenance();

    useFirebaseNotification();
    useBranding();

    const dispatch = useAppDispatch();
    const history = useWebHistory();
    const { modalContent, onToggle, ...modalEvent } = useAppSelector(({ modalEvent }) => modalEvent);

    const { isAuthed, isCreatingPassword, isCreatingProfile, isMaintenance } = useAppSelector(({ authentication }) => authentication);
    const { id } = useAppSelector(({ profile }) => profile);

    const location = useLocation();

    useCoBrowse();
    useIdentityUpdated();

    const { app } = useHref();

    const handleCloseModal = () => {
        dispatch(clearModalContent());
        onToggle && onToggle();

        // To show modal, do this something like this:
        // dispatch(setModalContent({
        //     modalContent: <LoginContainer />,
        //     title: 'Login'
        // }));
    };

    const { subscribe } = useChats();

    const isShowOpenAppPopup = useShowOpenAppPopup();

    useEffect(() => {
        if (isMaintenance && !location.pathname?.toLowerCase()?.includes('maintenance')) history.push('/maintenance');
    }, [isMaintenance, location.pathname]);

    useEffect(() => {
        if (isCreatingPassword) {
            history.replace('/create-password');
        }
    }, [isCreatingPassword]);

    useEffect(() => {
        if (isCreatingProfile) {
            history.replace('/update-profile');
        }
    }, [isCreatingProfile]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const groupId = searchParams.get('group_id');

        if (groupId) {
            if (isAuthed && id) {
                history.replace(`/groups?group_id=${groupId}`);
            } else {
                history.replace(`/login${location.search}`);
            }
        }
    }, [isAuthed, id]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const eventId = searchParams.get('event_id');

        if (eventId) {
            if (isAuthed && id) {
                history.replace(`/events/${eventId}`);
            } else {
                history.replace(`/login${location.search}`);
            }
        }
    }, [isAuthed, id]);

    useEffect(() => {
        const subscriber = subscribe();

        return () => {
            if (subscriber) subscribe();
        };
    }, [id, isAuthed]);

    return (
        <AppLoading isLoading={IS_LOADING_MAINTENANCE}>
            <ScrollToTop />
            {/* <Header /> */}
            {!isShowOpenAppPopup && <PublicEventBrowserPopup />}
            <div id='outer-container' className={app}>
                {/* {isAuthed && <Navbar />} */}
                <Switch>
                    {Object.values(ROUTES).map((route, i) => (
                        route.authedRoute
                            ? <PrivateRoute key={i} {...route} isAuthed={isAuthed || false} />
                            : <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>
            </div>
            <FlashNotification />
            <Modal
                {...modalEvent}
                isShow={!!modalContent &&
                    (typeof modalContent === 'string' ||
                        React.isValidElement(modalContent))}
                onToggle={handleCloseModal}
                className={app}>
                {modalContent}
            </Modal>
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true} />
        </AppLoading>
    );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes({ component: Component, ...rest }: { component: any }) {
    return (
        <Route
            {...rest}
            render={props => (
                // pass the sub-routes down to keep nesting
                <Component {...props} />
            )} />
    );
}

function PrivateRoute({ component: Component, isAuthed, ...rest }: { component: any, isAuthed: boolean }) {
    const location = useLocation();

    return (
        <Route {...rest} render={(routeProps) => (
            isAuthed
                ? <Component {...routeProps} />
                : <Redirect to={{
                    pathname: `/login${location.search}`,
                    state: { from: routeProps.location }
                }} />
        )} />
    );
}

export default App;
