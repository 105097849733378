import { useEffect, useState } from 'react';
import useHref from './useHref';
import fusiliersWhiteLogo from '~/assets/icons/fusiliers/logo-white.svg';
import fusiliersBlackLogo from '~/assets/icons/fusiliers/logo-black.svg';
import militaryLogo from '~/assets/icons/military/logo-name.svg';
import militaryThumbnail from '~/assets/icons/military/thumbnail.svg';
import fusiliersThumbnail from '~/assets/icons/fusiliers/thumbnail.svg';
import militaryLogoWithoutName from '~/assets/icons/military/logo.svg';
import fusiliersLogoWithoutName from '~/assets/icons/fusiliers/logo.svg';
import raLogo from '~/assets/icons/royalanglian/logo-name.svg';
import raLogoWithoutName from '~/assets/icons/royalanglian/logo.svg';
import raBlackLogo from '~/assets/icons/royalanglian/logo-black.png';
import raThumbnail from '~/assets/icons/royalanglian/thumbnail.svg';
import oubcLogo from '~/assets/icons/oubc/oubc-logo-light.png';
import oubcLogoDark from '~/assets/icons/oubc/oubc-logo-dark.png';

import brandingJSON from '~/branding.json';
import { APP, Branding } from '~/types';

const logos: Record<APP, { light: string; dark: string }> = {
    'Military App': {
        light: militaryLogo,
        dark: militaryLogo
    },
    Fusiliers: {
        light: fusiliersWhiteLogo,
        dark: fusiliersBlackLogo
    },
    'Royal Anglian Connect': {
        light: raLogo,
        dark: raBlackLogo
    },
    OUBC: {
        light: oubcLogo,
        dark: oubcLogoDark
    }
};

const logosWithoutName: Record<APP, string> = {
    'Military App': militaryLogoWithoutName,
    Fusiliers: fusiliersLogoWithoutName,
    'Royal Anglian Connect': raLogoWithoutName,
    OUBC: oubcLogoDark
};

const onGetLogo = (
    appName: APP,
    isUseBlackLogo?: boolean
) => {
    try {
        const importedLogo =
            logos[appName]?.[isUseBlackLogo ? 'dark' : 'light'] || militaryLogo;

        return importedLogo;
    } catch (error) {
        console.log(error);

        return '';
    }
};

const onGetLogoWithoutName = (
    appName: APP
) => {
    try {
        const importedLogo =
            logosWithoutName[appName] || militaryLogo;

        return importedLogo;
    } catch (error) {
        console.log(error);

        return '';
    }
};

const thumbnail = {
    'Military App': militaryThumbnail,
    Fusiliers: fusiliersThumbnail,
    'Royal Anglian Connect': raThumbnail,
    OUBC: oubcLogo
};

const onGetThumbnail = (appName: APP) => {
    try {
        const importedThumbnail = thumbnail[appName] || militaryThumbnail;

        return importedThumbnail;
    } catch (error) {
        console.log(error);

        return '';
    }
};

const useBranding = (isUseBlackLogo?: boolean) => {
    const { appName } = useHref();
    const environment =
        process.env.REACT_APP_ENV === 'prod'
            ? 'prod'
            : process.env.REACT_APP_ENV === 'staging'
                ? 'staging'
                : 'dev';

    const [favicon, setFavicon] = useState<string>(brandingJSON[appName].favicon);
    const [logo, setLogo] = useState<string>(
        onGetLogo(appName, isUseBlackLogo)
    );
    const [logoWithoutName, setLogoWithoutName] = useState<string>(
        onGetLogoWithoutName(appName)
    );

    const [thumbnail, setThumbnail] = useState<string>(
        onGetThumbnail(appName)
    );

    const [branding, setBranding] = useState<Branding>(() => {
        const branding = brandingJSON[appName];

        return {
            ...branding,
            siteTitle: branding.siteTitle[environment],
            firestoreChatsCollection:
                branding.firestoreChatsCollection[environment],
            firestoreMessagesCollection:
                branding.firestoreMessagesCollection[environment],
            firestoreMiscCollection: branding.firestoreMiscCollection[environment],
            firebaseDomainUri: branding.firebaseDomainUri[environment],
            webAppUrl: branding.webAppURL[environment],
            iosBundleId: branding.iosBundleId[environment],
            androidPackageName: branding.androidPackageName[environment]
        };
    });

    const onSetFavicon = (faviconPath: string) => {
        let link = document.querySelector("link[rel~='icon']");

        if (!link) {
            link = document.createElement('link');
            link.setAttribute('rel', 'icon');
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        setFavicon(faviconPath);
        link.setAttribute('href', faviconPath);
    };

    const onSetSiteTitle = (siteTitle: string) => {
        document.title = siteTitle;
    };

    const init = () => {
        const branding = brandingJSON[appName];

        onSetFavicon(branding.favicon);
        onSetSiteTitle(branding.siteTitle[environment]);
        setLogo(onGetLogo(appName, isUseBlackLogo));
        setLogoWithoutName(onGetLogoWithoutName(appName));
        setThumbnail(onGetThumbnail(appName));

        setBranding({
            ...branding,
            siteTitle: branding.siteTitle[environment],
            firestoreChatsCollection:
                branding.firestoreChatsCollection[environment],
            firestoreMessagesCollection:
                branding.firestoreMessagesCollection[environment],
            firebaseDomainUri: branding.firebaseDomainUri[environment],
            firestoreMiscCollection: branding.firestoreMiscCollection[environment],
            webAppUrl: branding.webAppURL[environment],
            iosBundleId: branding.iosBundleId[environment],
            androidPackageName: branding.androidPackageName[environment]
        });
    };

    useEffect(() => {
        init();
    }, [appName]);

    return {
        appName,
        favicon,
        thumbnail,
        branding,
        logo,
        logoWithoutName
    };
};

export default useBranding;
