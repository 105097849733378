import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GroupRolesState = {
    groupRoles: any,
};

const initialState: GroupRolesState = {
    groupRoles: {}
};

export const groupRolesSlice = createSlice({
    name: 'groupRoles',
    initialState,
    reducers: {
        setGroupRoles: (state, action: PayloadAction<GroupRolesState>) => {
            state.groupRoles = action.payload.groupRoles;
        }
    }
});

export const { setGroupRoles } = groupRolesSlice.actions;

export default groupRolesSlice.reducer;
