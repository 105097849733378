import { generateDynamicLink } from '~/api/firebase';
import useShowToast from './useShowToast';
import useBranding from './useBranding';

const useGenerateDynamicLink = () => {
    const { onError } = useShowToast();
    const { branding } = useBranding();

    const onGenerate = async (link: string = branding?.webAppUrl!) => {
        try {
            const resp = await generateDynamicLink({
                androidPackageName: branding?.androidPackageName!,
                domainUriPrefix: branding?.firebaseDomainUri!,
                iosAppStoreId: branding?.iosAppstoreId!,
                iosBundleId: branding?.iosBundleId!,
                link // link should be registered in Allowlist URL pattern
            });

            return resp?.shortLink;
        } catch (error) {
            onError(
                `Unable to generate dynamic link ${(error as Error)?.message}`
            );
        }
    };

    return onGenerate;
};

export default useGenerateDynamicLink;
