/* eslint-disable camelcase */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventType, Region } from '~/types';

export type EventFilterState = {
    countryOrRegion?: string;
    distance?: number;
    event_type?: EventType | string;
    lat?: number;
    long?: number;
    location?: string;
    keywords?: string;
    regions?: Region[];
    start_date?: string;
    end_date?: string;
};

const initialState: EventFilterState = {
    countryOrRegion: undefined,
    distance: 0,
    event_type: undefined,
    lat: 0,
    long: 0,
    location: undefined,
    keywords: undefined,
    regions: [],
    start_date: undefined,
    end_date: undefined
};

export const eventVisibilitySlice = createSlice({
    name: 'eventFilter',
    initialState,
    reducers: {
        setCountryOrRegion: (
            state,
            action: PayloadAction<EventFilterState>
        ) => {
            state.countryOrRegion = action.payload.countryOrRegion;
            state.location = undefined;
            state.long = 0;
            state.lat = 0;
            state.distance = 0;
        },
        setEventFilter: (state, action: PayloadAction<EventFilterState>) => {
            state.distance = action.payload.distance;
            state.event_type = action.payload.event_type;
            state.lat = action.payload.lat;
            state.long = action.payload.long;
            state.location = action.payload.location;
            state.keywords = action.payload.keywords;
            state.start_date = action.payload.start_date;
            state.end_date = action.payload.end_date;
        },
        clearCountryOrRegion: (state) => {
            state.countryOrRegion = undefined;
        },
        clearEventFilter: (state) => {
            state.distance = 0;
            state.event_type = undefined;
            state.lat = 0;
            state.long = 0;
            state.location = undefined;
            state.keywords = undefined;
            state.start_date = undefined;
            state.end_date = undefined;
        },
        setRegions: (state, action: PayloadAction<EventFilterState>) => {
            state.regions = action.payload.regions;
        }
    }
});

export const {
    setEventFilter,
    setCountryOrRegion,
    clearCountryOrRegion,
    clearEventFilter,
    setRegions
} = eventVisibilitySlice.actions;

export default eventVisibilitySlice.reducer;
