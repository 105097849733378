import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FireStore from '~/utils/Firestore';
import { onGetDataFromFireStore } from '~/utils/helpers';
import { isUndefined } from 'lodash';
import { onRegisterFirebaseToken } from '~/api/notifications';
import { useAppSelector } from '~/redux/hooks';
import useBranding from './useBranding';
import { API_CONFIG } from '~/config/constants';
import firebase from 'firebase';
import { setIsMaintenance } from '~/redux/modules/authentication';
import { useLocation } from 'react-router-dom';
import useWebHistory from './useWebHistory';

const useShowMaintenance = () => {
    const dispatch = useDispatch();
    const { branding, appName } = useBranding();
    const [isShow, setIsShow] = useState<boolean>();
    const location = useLocation();
    const history = useWebHistory();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { id, isAuthed, isInitializedFirebase } = useAppSelector(({ profile, authentication }) => ({ ...profile, ...authentication }));

    const onRedirectToMaintenance = () => {
        history.push('/maintenance');
    };

    const onRedirectToHome = () => {
        history.push('/');
    };

    const onShow = async (isShow: boolean) => {
        setIsShow(isShow);
        dispatch(setIsMaintenance({
            isMaintenance: isShow
        }));

        if (isShow && isAuthed) {
            if (firebase.messaging.isSupported()) {
                const messaging = firebase.messaging();

                await messaging.deleteToken();
            }

            await onRegisterFirebaseToken(id!, '');
        }
    };

    const subscribe = useCallback(() => {
        if (!isInitializedFirebase) return;

        const fireStore = new FireStore(branding.firestoreMiscCollection);

        return fireStore.getStore()
            .collection(branding.firestoreMiscCollection)
            .doc(API_CONFIG.API_ID)
            .onSnapshot((querySnapshot: any) => {
                const data: any = onGetDataFromFireStore(querySnapshot);

                if (data) {
                    const key = Object.keys(data || {})?.find(d => appName?.toLowerCase()?.includes(d?.toLowerCase()));

                    if (key) {
                        const appMisc = data[key];

                        if (!isUndefined(appMisc?.maintenance)) {
                            onShow(appMisc?.maintenance);
                        }
                    }
                }

                setIsLoading(false);
            });
    }, [isAuthed, id, API_CONFIG.API_ID, appName, branding.firestoreMiscCollection, isInitializedFirebase]);

    useEffect(() => {
        const subscriber = subscribe();

        return () => {
            if (subscriber) subscriber();
        };
    }, [isInitializedFirebase]);

    useEffect(() => {
        if (isShow) onRedirectToMaintenance();
        if (!isShow && !isUndefined(isShow) && location.pathname?.toLowerCase()?.includes('maintenance')) onRedirectToHome();
    }, [isShow, location.pathname]);

    return {
        isLoading
    };
};

export default useShowMaintenance;
