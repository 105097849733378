import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProfileState = {
    industries: string[],
};

const initialState: ProfileState = {
    industries: []
};

export const profileSlice = createSlice({
    name: 'industries',
    initialState,
    reducers: {
        setIndustries: (state, action: PayloadAction<ProfileState>) => {
            state.industries = action.payload.industries;
        }
    }
});

export const { setIndustries } = profileSlice.actions;

export default profileSlice.reducer;
